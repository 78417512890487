const getDefaultState = () => {
    return {
        idToken: null,
        user: null,
        lead: null,
        token: null,
        socialToken: null,
        enviroments: null,
        qualifications: [],
        landingPageConfig: {
            background: null,
            title: 'Nosso <span class="yellow--text">sol</span> pode <span class="white--text">salvar</span> o mundo e suas finanças.',
            subtitle: 'Economize por ano o equivalente ao valor de 1 conta! Receba energia limpa de fazendas solares.',
            subtitle_color: 'blue--text',
            movie: 'aXmcmbiNcjg',
            call_to_action: 'SIMULE AGORA'
        },
        utm: null,
        tracking: null,
        referral_code: null,
        coupon: null,
        plans: [],
        plan: null,
        utilityCompanies: [],
        consumerUnits: []
    }
}

// initial state
const state = getDefaultState()

export default {
  getDefaultState,
  ...state
}