var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        {
          staticClass: "d-flex justify-center align-center mb-6",
          attrs: {
            color: _vm.$vuetify.theme.dark ? "grey darken-3" : "grey lighten-4",
            flat: "",
            tile: "",
            height: "100vh"
          }
        },
        [
          _c("v-img", {
            attrs: {
              src: require("@/assets/novo/juntos_motion.gif"),
              "max-height": "64",
              "max-width": "64"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }