var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "pr-0 pl-0", attrs: { align: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "12", md: "12", lg: "12" } },
        [
          _c("v-img", {
            attrs: {
              width: "100%",
              src:
                "https://enercred.com.br/wp-content/themes/enercred-1/assets/min-images/servico/mockup-celular--mobile.png"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        {
          staticClass: "pa-6",
          attrs: { cols: "12", sm: "12", md: "12", lg: "12" }
        },
        [
          _c("v-container", { staticClass: "pa-6" }, [
            _c("h2", [_vm._v("Democratização do acesso à energias limpas.")]),
            _c("p", { staticClass: "pt-12" }, [
              _vm._v(
                "A Juntos Energia faz com que consumidores residenciais e PME’s possam aderir às fontes renováveis de forma simplificada. Dessa forma, todos podem apoiar à preservação do Planeta e reduzir gastos de eletricidade com apenas alguns cliques. Se você tem um consumo médio superior a R$ 150,00, faça uma simulação da sua ECONOMIA."
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "text-center pb-12" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "pl-16 pr-16",
                  attrs: {
                    color: "primary",
                    href: "#simular",
                    large: "",
                    rounded: ""
                  }
                },
                [_vm._v(_vm._s(_vm.config.call_to_action || "SIMULE AGORA"))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }