<template>
    <v-card class="mx-auto rounded-xl elevation-1 pa-2">
        <v-card-title class="ml-2 mr-2">
            <h3>Faça uma simulação</h3>
        </v-card-title>
        <v-card-text>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                @submit.prevent="submit"
                class="ml-2 mr-2"
            >
                <div class="font-weight-bold">Quanto você gasta de energia por mês?</div>
                <v-text-field
                    v-model.number="average_consumption"
                    required
                    prefix="R$"
                    :rules="averageRules"
                    type="number"
                ></v-text-field>
                <div class="font-weight-bold">Qual é a sua concessionária?</div>
                <v-row class="pt-2 pb-4">
                    <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        align="center"
                    >
                        <v-btn small rounded color="primary" dark @click.prevent="setUtility('cemig')" :outlined="utility === 'outra'" block>
                            <v-icon v-if="utility !== 'outra'" color="white" left>mdi-check</v-icon>
                            Cemig</v-btn>
                    </v-col>
                    <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        align="center"
                    >
                        <v-btn small rounded color="primary" dark :outlined="utility !== 'outra'" @click.prevent="setUtility('outra')" block>
                            <v-icon v-if="utility === 'outra'" color="white" left>mdi-check</v-icon>
                            Outra
                        </v-btn>
                    </v-col>
                </v-row>
                <div class="font-weight-bold">Para minha</div>
                <v-row class="pt-2 pb-4">
                    <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        align="center"
                    >
                        <v-btn small rounded color="primary" dark @click.prevent="setPerson('pf')" :outlined="person_type === 'pj'" block>
                            <v-icon v-if="person_type !== 'pj'" color="white" left>mdi-check</v-icon>
                            Casa
                            <v-icon :color="person_type === 'pf'?'white':'primary'" right>mdi-home-outline</v-icon>
                        </v-btn>
                            
                    </v-col>
                    <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        align="center"
                    >
                        <v-btn small rounded color="primary" dark :outlined="person_type !== 'pj'" @click.prevent="setPerson('pj')" block>
                            <v-icon v-if="person_type === 'pj'" color="white" left>mdi-check</v-icon>
                            Empresa
                            <v-icon color="primary" right v-if="person_type !== 'pj'">mdi-office-building-marker</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-btn
                    color="secondary"
                    @click="submit"
                    block
                    rounded
                    class="mt-3"
                    :disabled="!valid"
                >
                    Simular
                </v-btn>
            </v-form>
        </v-card-text>
    </v-card>
</template>
<script>
  import { mapActions, mapState } from 'vuex'
  export default {
    data: () => ({
      average_consumption: null,
      averageRules: [
        v => !!v || "Por favor, informe quanto você gasta em sua conta de luz",
        v => v > 149 || "Ops! Você precisa gastar pelo menos 150 reais para simular"
      ],
      utility: 'cemig',
      person_type: 'pf',
      valid: false,
      isProd: false,
    }),
    computed: {
        ...mapState(['lead', 'utm', 'tracking', 'referral_code'])
    },
    watch: {
        lead() {
            this.average_consumption = this.lead.average_consumption
            this.utility = this.lead.utility || 'cemig'
            this.person_type = this.lead.person_type || 'pf'
        }
    },
    mounted() {
        this.isProd = process.env.NODE_ENV === "production"
        if(this.$route.query.utility) this.utility = this.$route.query.utility
        if(this.$route.query.average_consumption) this.average_consumption = this.$route.query.average_consumption
        if(this.$route.query.person_type) this.person_type = this.$route.query.person_type
    },
    methods: {
      ...mapActions(['saveLead']),
      submit () {
        if(this.valid && this.$refs.form.validate()) {
            let lead = {
                ...this.lead,
                average_consumption: this.average_consumption,
                utility: this.utility,
                person_type: this.person_type,
                ...this.utm,
                tracking: this.tracking,
                step: this.$route.name,
                new_onboarding: true,
            }
            if(this.referral_code) lead.promo_code = this.referral_code
            this.saveLead(lead)
                .then(() => {
                    this.$gtm.trackEvent({
                            event: 'simular',
                            category: "onboard",
                            action: "click",
                            label: "Cadastro do valor de consumo na página de Landing Pages",
                            value: this.average_consumption,
                            noninteraction: false, // Optional
                            step: this.$route.name,
                            person_type: this.lead?.person_type,
                            email: this.user?.email || this.lead?.email,
                            phone: this.user?.phone || this.lead?.phone,
                    });

                    this.$router.push({path: '/simular', query: this.$route?.query})
                })
                .catch(err => {
                    console.error(err)
                })
        }
      },
      setUtility(value) {
          this.utility = value
      },
      setPerson(value) {
          this.person_type = value
      },
      setLead(lead) {
        if(lead.average_consumption) this.average_consumption = lead.average_consumption
        if(lead.utility) this.utility = lead.utility
        if(lead.person_type) this.person_type = lead.person_type
      },
    },
  }
</script>