<template>
  <v-container class="ma-0" fluid wrap>
    <v-row>
      <v-col cols="12" xs="12" md="6" lg="4" class="d-none d-md-block bg"/>
      <v-col
        cols="12"
        md="6"
        lg="4"
        class="pa-2 form"
        style="background: #efefef"
      >
        <v-container class="pa-6" v-if="utility === 'other'">
          <v-img src="@/assets/novo/logo_roxo.svg" width="230px" />
          <h1 class="pt-12">{{ lead.name | firstName }}</h1>
          <h3>
            <p>Obrigado!</p>
            <p class="body-1 font-weight-bold">A Juntos ainda não chegou na sua região. </p>
            <p class="body-1">Já iniciamos o processo de expansão nacional dos nossos serviços. Você já <strong>garantiu seu lugar</strong> na fila de espera, assim que chegarmos por ai, você será um dos <strong>primeiros a saber</strong>.</p>
          </h3>
          <v-divider/>
          <social-media class="pt-9"/>
        </v-container>
        <v-container class="pa-6" v-else>
          <v-img src="@/assets/novo/logo_roxo.svg" width="230px" />
          <h1 class="pt-12">{{ lead.name | firstName }}</h1>
          <h3>
            <p>Obrigado!</p>
            <p class="body-1 font-weight-bold">
              A Juntos acabou de lançar na sua região e você poderá ser um dos primeiros do estado a fazer a portabilidade de energia limpa da Juntos! 
              Os nossos especialistas vão entrar em contato para tirar dúvidas e fazer a migração sem burocracia.
            </p>
            <p class="body-1">Fique de olho no seu Telefone, WhatsApp ou E-mail (confira sempre na sua caixa de spam pelo e-mail vendas@juntosenergia.com.br)</p>
            <p class="body-1">Até mais!</p>
          </h3>
          <v-divider/>
          <social-media class="pt-9"/>
        </v-container>
      </v-col>
      <v-col cols="12" xs="12" md="6" lg="4" class="d-none d-md-block bg"/>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
import SocialMedia from '../components/SocialMedia.vue'
export default {
  components: { SocialMedia },
  created() {
    if (!this.user) {
      this.getLocalUser();
    }

    
    if (!this.lead) {
      this.getLocalLead();
    }
    this.utility = this.user?.utility || this.lead?.utility || 'other'
    if(this.utility != 'neoenergia-pe' && this.utility != 'cpfl-piratininga') this.utility = 'other'
    if (this.user && this.lead && this.utility === 'other' && !this.qualifications) {
      this.loading = true;
      this.$gtm.trackEvent({
        event: 'concessionaria_incompativel',
        category: "onboard",
        action: "pageview",
        label: "Acessou pagina de validação de qualificação",
        noninteraction: false, // Optional
        step: this.$route.name,
        email: this.user?.email || this.lead?.email,
        phone: this.user?.phone || this.lead?.phone,
        person_type: this.user?.person_type || this.lead?.person_type,
        utility:  this.user?.utility || this.lead?.utility
      });
    }
    
  },
  data() {
    return {
      loading: false,
      utility: 'other'
    };
  },
  computed: {
    ...mapState(["lead", "user"]),
  },
  methods: {
    ...mapActions(["getLocalUser", "getLocalLead"]),
  },
  filters: {
    firstName: function (v) {
      if (!v) return "";
      else {
        let firstname = v.split(" ")[0];
        return firstname[0].toUpperCase() + firstname.substr(1);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.app {
  background: #efefef;
  color: darkblue;
  height: 100vh;
}
</style>