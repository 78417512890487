var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("v-row", { staticClass: "justify-center text-center" }, [
        _c("h4", { staticClass: "grey--text" }, [
          _vm._v("Acompanhe nossas redes socias")
        ])
      ]),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "3" } }, [
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.facebook.com/juntosenergia/",
                  target: "_blank"
                }
              },
              [
                _c("v-img", {
                  attrs: {
                    "max-width": "48px",
                    src: require("@/assets/social-media/facebook.svg")
                  }
                })
              ],
              1
            )
          ]),
          _c("v-col", { attrs: { cols: "3" } }, [
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.instagram.com/juntosenergia/?hl=pt",
                  target: "_blank"
                }
              },
              [
                _c("v-img", {
                  attrs: {
                    "max-width": "48px",
                    src: require("@/assets/social-media/instagram.svg")
                  }
                })
              ],
              1
            )
          ]),
          _c("v-col", { attrs: { cols: "3" } }, [
            _c(
              "a",
              {
                attrs: {
                  href: "https://br.linkedin.com/company/juntosenergia",
                  target: "_blank"
                }
              },
              [
                _c("v-img", {
                  attrs: {
                    "max-width": "48px",
                    src: require("@/assets/social-media/linkedin.svg")
                  }
                })
              ],
              1
            )
          ]),
          _c("v-col", { attrs: { cols: "3" } }, [
            _c(
              "a",
              {
                attrs: {
                  href:
                    "https://www.youtube.com/channel/UChpPlSLAhcjU6JsxrnRw76A",
                  target: "_blank"
                }
              },
              [
                _c("v-img", {
                  attrs: {
                    "max-width": "48px",
                    src: require("@/assets/social-media/youtube.svg")
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }