var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "ml-1 mr-1" },
            [
              _c("v-col", { staticClass: "ma-1" }, [
                _c("h1", {
                  staticClass: "font-weight-medium d-none d-md-block",
                  domProps: { innerHTML: _vm._s(_vm.config.title) }
                }),
                _c("div", { staticClass: "pt-8 d-none d-md-block" }, [
                  _c("h2", {
                    class:
                      "font-weight-light " +
                      (_vm.config.subtitle_color || "blue--text"),
                    domProps: { innerHTML: _vm._s(_vm.config.subtitle) }
                  })
                ]),
                _c("h2", {
                  staticClass: "font-weight-medium d-md-none",
                  domProps: { innerHTML: _vm._s(_vm.config.title) }
                }),
                _c("div", { staticClass: "pt-8 d-flex d-md-none" }, [
                  _c("h3", {
                    class:
                      "font-weight-light " +
                      (_vm.config.subtitle_color || "blue--text"),
                    domProps: { innerHTML: _vm._s(_vm.config.subtitle) }
                  })
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("how-works")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }