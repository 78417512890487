// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/bg_onboarding.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.bg {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") repeat center center;\n    /* position: absolute; */\n    opacity: 1;\n    /* background: linear-gradient(180deg, rgb(5, 23, 77) -10%, #27408B 80%, #27408B 100%); */\n    -o-object-fit: cover;\n       object-fit: cover;\n    width: 100%;\n    min-height: 100vh;\n}\n", ""]);
// Exports
module.exports = exports;
