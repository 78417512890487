<template>
    <!-- <v-container fluid fill-height class="pt-0 pb-0"> -->
        <v-row align="center" class="pr-0 pl-0">
            <v-col cols="12" sm="12" md="12" lg="12">
                <v-img width="100%" src="https://enercred.com.br/wp-content/themes/enercred-1/assets/min-images/servico/mockup-celular--mobile.png"/>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" class="pa-6">
                <v-container class="pa-6">
                    <h2>Democratização do acesso à energias limpas.</h2>
                    <p class="pt-12">A Juntos Energia faz com que consumidores residenciais e PME’s possam aderir às fontes renováveis de forma simplificada. Dessa forma, todos podem apoiar à preservação do Planeta e reduzir gastos de eletricidade com apenas alguns cliques.
                    Se você tem um consumo médio superior a R$ 150,00, faça uma simulação da sua ECONOMIA.</p>
                </v-container>
                <div class="text-center pb-12"><v-btn color="primary" href="#simular" large rounded class="pl-16 pr-16">{{config.call_to_action || 'SIMULE AGORA'}}</v-btn></div>
            </v-col>
        </v-row>
    <!-- </v-container> -->
</template>
<script>
  export default {
    props: ['config']
  }
</script>
<style scoped>
</style>