<template>
    <div>
        <v-skeleton-loader
          v-bind="attrs"
          type="list-item-avatar-three-line, image, article"
          v-if="loading"
          width="400"
        ></v-skeleton-loader>
        <v-card 
            class="mx-auto"
            outlined
            rounded
            v-else
        >
            <v-list-item three-line>
                <v-list-item-content>
                    <div class="overline mb-4">
                        Não conseguimos identificar nenhum imóvel pré-cadastrado.
                    </div>
                    <v-list-item-subtitle class="font-weight-bold"  v-if="change_bill">Por favor, envie uma foto da sua conta de luz</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                    tile
                    size="40"
                >
                    <v-icon x-large rigth color="grey lighten-1" @click.prevent="openQualificationDetail(null, 'not_found')">mdi-information</v-icon>
                </v-list-item-avatar>
            </v-list-item>
            
            <div class="pa-4" v-if="change_bill">
            <!-- <v-card-actions class="pa-4" v-if="type === 'NOT_ACCOUNT'"> -->
                <v-row>
                    <v-col cols="12">
                        <v-file-input
                            accept="image/*,application/pdf"
                            label="Anexar Conta"
                            @change="detectFiles($event)"
                            name="file"
                            v-model="file"
                            :rules="rules"
                            @click:append="clearBill"
                            :append-icon="lead.bill_links?'mdi-arrow-left-circle':''"
                        ></v-file-input>
                    </v-col>
                </v-row>
                <v-row class="text-center">
                    <!-- <v-col cols="6">
                        <v-btn
                            rounded
                            text
                            outlined
                            v-if="!bill_url"
                        >
                            Enviar Depois
                        </v-btn>
                    </v-col>
                    <v-spacer/> -->
                    <!-- <v-col cols="6">
                        <v-btn
                            color="primary"
                            rounded
                            @click="detectFiles(file)"
                        >
                            Enviar Conta
                        </v-btn>
                    </v-col> -->
                </v-row>
            </div>
            <div v-for="(bill_url, i) in files" :key="i" class="pa-4 justify-center align-center">
                <v-row class="pa-2">
                    <v-col cols="11">
                        <v-img v-if="bill_url && getExt(bill_url.url) === '.pdf'" src="@/assets/pdf.svg" width="200px"/>
                        <v-img v-else :src="bill_url.url"/>
                    </v-col>
                    <!-- <v-col cols="1">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    @click="editBill" 
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-pencil
                                </v-icon>
                            </template>
                            <span>Alterar Foto</span>
                        </v-tooltip>
                    </v-col> -->
                    <v-col cols="1">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    @click="delBill(i, bill_url.bill_ref)" 
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-close
                                </v-icon>
                            </template>
                            <span>Excluir Foto</span>
                        </v-tooltip>
                        <!-- <v-icon @click="editBill">mdi-pencil</v-icon> -->
                    </v-col>
                </v-row>
            </div>
        </v-card>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { sendBill, getFileNameExt, deleteBill } from '../../services/qualifications'
import { firestorage } from '../../firebase/firestorage'
export default {
    props: ['lead'],
    computed: {
        ...mapState(['user']),
    },
    data: () => ({
      file: null,
      rules: [
        value => !value || value.size < 3000000 || 'Arquivo precisa ser menor que 3 MB!',
      ],
      loading: false,
      change_bill: true,
      bill_url: false,
      bill_link: false,
      files: [],
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
    }),
    created() {
        this.loading = true
        if(this.lead && this.lead.bill_links && this.lead.bill_links.length > 0) {
            for(let i=0; i < (this.lead.bill_links.length); i++) {
                let bill = firestorage.ref().child(this.lead.bill_links[i].link)
                bill.getDownloadURL()
                    .then((url) => {
                        this.bill_url = url
                        let file = {
                            bill_ref: bill,
                            url: url
                        }
                        this.files.push(file)
                        this.change_bill = false
                        this.loading = false
                    })
                    .catch((error) => {
                        console.error(error)
                        this.loading = false
                        return null
                    })
            }
        }
        else {
            this.loading = false
            return null
        }
    },
    watch: {
        lead() {
            this.loading = true
            if(this.lead && this.lead.bill_links && this.lead.bill_links.length > 0) {
                for(let i=0; i < this.lead.bill_links.length; i++) {
                    let bill = firestorage.ref().child(this.lead.bill_links[i].link)
                    return bill.getDownloadURL()
                        .then((url) => {
                            this.bill_url = url
                            let file = {
                                bill_ref: bill,
                                url: url
                            }
                            this.files.push(file)
                            this.change_bill = false
                            this.loading = false
                        })
                        .catch((error) => {
                            console.error(error)
                            this.loading = false
                            return null
                        })
                }
            }
        }
    },
    methods: {
        detectFiles(e) {
            this.loading = true
            if(!this.user || this.user === 'undefined') {
                this.loading = false
                return
            }
            // let fileList = e.target.files || e.dataTransfer.files;
            // let file = fileList[0]
            let file = e
            if(!this.lead) {
                console.error('Ops! Erro ao buscar os dados de cadastro!')
                return
            }
            if(!file) {
                console.error('Ops! Arquivo inválido ou inexistente!')
                return
            }
            sendBill(false, this.user.id, file, this.lead)
                .then(() => {
                    if(this.lead && this.lead.bill_links && this.lead.bill_links.length > 0) {
                        for(let i=0; i < this.lead.bill_links.length; i++) {
                            let bill = firestorage.ref().child(this.lead.bill_links[i].link)
                            return bill.getDownloadURL()
                                .then((url) => {
                                    this.bill_url = url
                                    let file = {
                                        bill_ref: bill,
                                        url: url
                                    }
                                    this.files.push(file)
                                    this.change_bill = false
                                    this.loading = false
                                })
                                .catch((error) => {
                                    console.error(error)
                                    this.loading = false
                                    return null
                                })
                        }
                    }
                    this.loading = false
                })
                .catch(err => {
                    console.error(err)
                    this.loading = false
                })

        },
        editBill() {
            this.change_bill = true
        },
        clearBill() {
            this.change_bill = false
        },
        getExt(name) {
            let ext = getFileNameExt(name)
            return ext.substring(0,4)
        },
        delBill(index, url) {
            this.loading = true
            let bills = this.lead.bill_links.splice(index, 1)
            let _files = this.files.splice(index, 1)
            deleteBill(false, this.lead.email, url, this.lead, bills)
                .then(() => {
                    this.files = _files
                    this.loading = false
                })
                .catch(err => {
                    console.error(err)
                    this.loading = false
                })
        }
    }
}
</script>