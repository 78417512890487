<template>
<div>
  <v-container>
    <v-row class="ml-1 mr-1">
      <v-col class="ma-1">
        <!-- Desktop -->
        <h1 class="font-weight-medium d-none d-md-block" v-html="config.title">
        </h1>
        <div class="pt-8 d-none d-md-block">
          <!-- Saiba como funciona <v-icon rigth color="#fff">mdi-arrow-right</v-icon> -->
          <h2 :class="'font-weight-light ' + (config.subtitle_color || 'blue--text')" v-html="config.subtitle"></h2>
        </div>
        <!-- Mobile and Tablet -->
        <h2 class="font-weight-medium d-md-none" v-html="config.title">
        </h2>
        <div class="pt-8 d-flex d-md-none">
          <!-- Saiba como funciona <v-icon rigth color="#fff">mdi-arrow-right</v-icon> -->
          <h3 :class="'font-weight-light ' + (config.subtitle_color || 'blue--text')" v-html="config.subtitle"></h3>
        </div>
      </v-col>
    </v-row>
  </v-container>
  <how-works/>
</div>
</template>

<script>
  import { eventBus } from '../../main'
  import HowWorks from '../Simulador/HowWorks.vue'
  export default {
    name: 'BannerSimulador',
    props: ['config'],
    data: () => ({
    }),
    components: {
      HowWorks
    },
    methods: {
      showHowWorks() {
        eventBus.$emit('open-dialog_howworks')
        
        // Grava abertura de página no Amplitude
        // var eventProperties = {
        //     "page": 'howWorks'
        // };
        // this.$amplitude.logEvent('VIEWED', eventProperties);
      }
    }
  }
</script>
<style scoped>
  .video {
    border-radius: 10px;
  }
</style>