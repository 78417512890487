var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", {
            staticClass: "d-none d-md-block bg",
            attrs: { cols: "12", xs: "12", md: "6", lg: "4" }
          }),
          _c(
            "v-col",
            {
              staticClass: "pa-0 form",
              attrs: { cols: "12", md: "4", lg: "3" }
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "overflow-hidden",
                  attrs: { flat: "", color: "#efefef" }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "pa-3 mt-0", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", align: "center" } },
                        [
                          _c("v-img", {
                            attrs: {
                              src: require("@/assets/novo/logo.png"),
                              width: "50%"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "ml-3 mr-3 pl-3 pr-3", attrs: { flat: "" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-2 mb-0 pb-0" },
                    [
                      _c("v-col", {
                        staticClass: "mb-0",
                        attrs: { cols: "1", md: "3", lg: "3" }
                      }),
                      _c(
                        "v-col",
                        {
                          staticClass: "mb-0",
                          attrs: { cols: "10", md: "6", lg: "6" }
                        },
                        [
                          _c("v-combobox", {
                            attrs: {
                              value: _vm.plan,
                              items: _vm.plans,
                              "item-text": "name",
                              label: "Planos",
                              outlined: "",
                              rounded: "",
                              dense: ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.updatePlan($event)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-col", {
                        staticClass: "mb-0",
                        attrs: { cols: "1", md: "3", lg: "3" }
                      })
                    ],
                    1
                  ),
                  _c("benefits", {
                    attrs: { discount: _vm.discount },
                    on: { udpate_discount: _vm.onUpdateDiscount }
                  })
                ],
                1
              ),
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "ml-0 mr-0 pb-2" },
                    [
                      !_vm.subscribe &&
                      _vm.lead &&
                      _vm.lead.person_type === "pf"
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    rounded: "",
                                    block: "",
                                    color: "primary",
                                    large: ""
                                  },
                                  on: { click: _vm.setSubscribe }
                                },
                                [_vm._v("ASSINAR AGORA")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            align: "center",
                            cols:
                              !_vm.subscribe &&
                              _vm.lead &&
                              _vm.lead.person_type === "pf"
                                ? 12
                                : 12
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                small: "",
                                outlined: "",
                                color: "green",
                                rounded: "",
                                dark: ""
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.showHowWorks($event)
                                }
                              }
                            },
                            [_vm._v("Clique aqui e veja como funciona")]
                          )
                        ],
                        1
                      ),
                      !_vm.subscribe &&
                      _vm.lead &&
                      _vm.lead.person_type === "pj"
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    rounded: "",
                                    block: "",
                                    color: "primary",
                                    href:
                                      "https://api.whatsapp.com/send/?phone=5535998625580&text=Ol%C3%A1!",
                                    target: "_blank"
                                  }
                                },
                                [_vm._v("Falar com um consultor")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.subscribe
                ? _c("steps", {
                    staticClass: "mt-3",
                    attrs: {
                      step: 1,
                      total: 5,
                      itens: ["Garanta sua vaga", "Imóveis"]
                    }
                  })
                : _vm._e(),
              _c(
                "v-container",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.subscribe,
                      expression: "subscribe"
                    }
                  ]
                },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      staticClass: "ml-2 mr-2 mt-6",
                      attrs: { "lazy-validation": "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit($event)
                        }
                      },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "", "pt-0": "" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0",
                                  attrs: { cols: "12", sm: "12", md: "12" }
                                },
                                [
                                  _c("v-combobox", {
                                    attrs: {
                                      value: _vm.plan,
                                      items: _vm.plans,
                                      "item-text": "name",
                                      label: "Planos",
                                      outlined: "",
                                      rounded: "",
                                      dense: ""
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.updatePlan($event)
                                      }
                                    }
                                  }),
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "###.###.###-##",
                                        expression: "'###.###.###-##'"
                                      }
                                    ],
                                    ref: "cpf",
                                    attrs: {
                                      label: "CPF do titular",
                                      required: "",
                                      rules: _vm.cpfRules,
                                      filled: "",
                                      rounded: "",
                                      dense: ""
                                    },
                                    model: {
                                      value: _vm.cpf,
                                      callback: function($$v) {
                                        _vm.cpf = $$v
                                      },
                                      expression: "cpf"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "pt-0 mt-0" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 mt-0",
                                  attrs: { cols: "12", sm: "12", md: "12" }
                                },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      label:
                                        "Declaro que sou o titular da Conta de Energia",
                                      type: "checkbox",
                                      rules: _vm.titularRules,
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.titular,
                                      callback: function($$v) {
                                        _vm.titular = $$v
                                      },
                                      expression: "titular"
                                    }
                                  }),
                                  _c("span", { staticClass: "caption" }, [
                                    _vm._v(
                                      "Esse serviço pode ser contratato apenas pelo titular da conta de energia."
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "pt-0" },
                            [
                              _vm.error !== ""
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12", sm: "12", md: "12" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "caption red--text text-center"
                                        },
                                        [_vm._v(_vm._s(_vm.error))]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "pt-0" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0",
                                  attrs: { cols: "12", sm: "12", md: "12" }
                                },
                                [
                                  !_vm.error
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            large: "",
                                            rounded: "",
                                            block: "",
                                            color: "primary",
                                            disabled: !_vm.valid || _vm.loading,
                                            loading: _vm.loading
                                          },
                                          on: { click: _vm.submit },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "loader",
                                                fn: function() {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(_vm.loading_btn)
                                                      )
                                                    ]),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "custom-loader"
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { light: "" }
                                                          },
                                                          [_vm._v("mdi-cached")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            false,
                                            3664109088
                                          )
                                        },
                                        [_vm._v(" CONTINUAR ")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12", md: "12" } },
                                [
                                  (_vm.lead &&
                                    _vm.lead.average_consumption > 300) ||
                                  this.error
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "mt-3",
                                          attrs: {
                                            href:
                                              "https://api.whatsapp.com/send/?phone=5535998625580&text=Ol%C3%A1!",
                                            target: "_blank",
                                            outlined: "",
                                            rounded: "",
                                            block: "",
                                            color: "green",
                                            disabled: _vm.loading
                                          }
                                        },
                                        [_vm._v("Falar com um consultor")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { ref: "focusableDiv", attrs: { tabindex: "-1" } })
            ],
            1
          ),
          _c("v-col", {
            staticClass: "d-none d-md-block bg",
            attrs: { cols: "12", xs: "12", md: "6", lg: "4" }
          })
        ],
        1
      ),
      _c(
        "v-bottom-sheet",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { flat: "" } },
                [
                  _c("v-spacer"),
                  _c(
                    "v-toolbar-items",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", text: "" },
                          on: {
                            click: function($event) {
                              _vm.dialog = false
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { color: "grey" } }, [
                            _vm._v("mdi-close")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("validation", { on: { startContract: _vm.startContract } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-bottom-sheet",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.dialog_isMember,
            callback: function($$v) {
              _vm.dialog_isMember = $$v
            },
            expression: "dialog_isMember"
          }
        },
        [
          _vm.isMember && _vm.isMember.code
            ? _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "" } },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { dark: "", text: "" },
                              on: {
                                click: function($event) {
                                  _vm.dialog_isMember = false
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { color: "grey" } }, [
                                _vm._v("mdi-close")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    [
                      _c("div", {
                        staticClass: "pa-6",
                        domProps: { innerHTML: _vm._s(_vm.isMember.message) }
                      }),
                      _c(
                        "v-row",
                        { staticClass: "pa-6" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", align: "center" } },
                            [
                              _vm.isMember.code === "canDecided"
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        large: "",
                                        rounded: "",
                                        color: "#27408B",
                                        loading: _vm.loading,
                                        dark: "",
                                        block: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.forceSave(
                                            _vm.lead,
                                            _vm.isMember.customer_id
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Continuar Assinatura")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", align: "center" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    small: "",
                                    color: "green",
                                    rounded: "",
                                    outlined: "",
                                    href:
                                      "https://api.whatsapp.com/send/?phone=5535998625580&text=Ol%C3%A1!",
                                    target: "_blank"
                                  }
                                },
                                [_vm._v("Falar com um consultor")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }