var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.plan
    ? _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "v-dialog",
            {
              attrs: { width: "500" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              { attrs: { text: "", small: "" } },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-decoration-underline text-none"
                              },
                              [_vm._v(" Entenda como funciona ")]
                            )
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                1719471628
              ),
              model: {
                value: _vm.dialog_plan_conditions,
                callback: function($$v) {
                  _vm.dialog_plan_conditions = $$v
                },
                expression: "dialog_plan_conditions"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "text-h5 grey lighten-2" },
                    [
                      _vm._v(
                        " Plano: " + _vm._s(_vm.plan ? _vm.plan.name : "") + " "
                      )
                    ]
                  ),
                  _c("p", { staticClass: ".subtitle-1 pa-3" }, [
                    _vm._v("Condições")
                  ]),
                  _c("v-card-text", {
                    domProps: { innerHTML: _vm._s(_vm.plan.conditions) }
                  }),
                  _c("v-card-text", {
                    domProps: { innerHTML: _vm._s(_vm.penalty) }
                  }),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function($event) {
                              _vm.dialog_plan_conditions = false
                            }
                          }
                        },
                        [_vm._v(" Fechar ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }