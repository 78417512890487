var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c(
            "v-skeleton-loader",
            _vm._b(
              {
                attrs: {
                  type: "list-item-avatar-three-line, image, article",
                  width: "400"
                }
              },
              "v-skeleton-loader",
              _vm.attrs,
              false
            )
          )
        : _c(
            "v-card",
            { staticClass: "mx-auto", attrs: { outlined: "", rounded: "" } },
            [
              _c(
                "v-list-item",
                { attrs: { "three-line": "" } },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("div", { staticClass: "overline mb-4" }, [
                        _vm._v(
                          " Não conseguimos identificar nenhum imóvel pré-cadastrado. "
                        )
                      ]),
                      _vm.change_bill
                        ? _c(
                            "v-list-item-subtitle",
                            { staticClass: "font-weight-bold" },
                            [
                              _vm._v(
                                "Por favor, envie uma foto da sua conta de luz"
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-avatar",
                    { attrs: { tile: "", size: "40" } },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            "x-large": "",
                            rigth: "",
                            color: "grey lighten-1"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.openQualificationDetail(
                                null,
                                "not_found"
                              )
                            }
                          }
                        },
                        [_vm._v("mdi-information")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.change_bill
                ? _c(
                    "div",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-file-input", {
                                attrs: {
                                  accept: "image/*,application/pdf",
                                  label: "Anexar Conta",
                                  name: "file",
                                  rules: _vm.rules,
                                  "append-icon": _vm.lead.bill_links
                                    ? "mdi-arrow-left-circle"
                                    : ""
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.detectFiles($event)
                                  },
                                  "click:append": _vm.clearBill
                                },
                                model: {
                                  value: _vm.file,
                                  callback: function($$v) {
                                    _vm.file = $$v
                                  },
                                  expression: "file"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-row", { staticClass: "text-center" })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.files, function(bill_url, i) {
                return _c(
                  "div",
                  { key: i, staticClass: "pa-4 justify-center align-center" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "pa-2" },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "11" } },
                          [
                            bill_url && _vm.getExt(bill_url.url) === ".pdf"
                              ? _c("v-img", {
                                  attrs: {
                                    src: require("@/assets/pdf.svg"),
                                    width: "200px"
                                  }
                                })
                              : _c("v-img", { attrs: { src: bill_url.url } })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "1" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.delBill(
                                                        i,
                                                        bill_url.bill_ref
                                                      )
                                                    }
                                                  }
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v(" mdi-close ")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [_c("span", [_vm._v("Excluir Foto")])]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }