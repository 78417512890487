<template>
    <v-dialog
        v-model="dialog_qualification_details"
        transition="dialog-bottom-transition"
        scrollable
        persistent
    >
        <!-- <v-layout row class="pa-3"> -->
            <v-container>
                <v-row justify="space-around">
                    <v-card>
                        <div class="pa-6 pt-12 justify-center text-left">
                            
                            <h1 :class="typeLabel.color + `--text`" >
                                <v-icon right :color="typeLabel.color">
                                    {{typeLabel.icon}}
                                </v-icon>
                                {{uc}}
                            </h1>
                            <h3 class="pt-1">{{typeLabel.lable}}</h3>
                            <p v-html="typeLabel.description"/>
                        </div>
                        <div class="pa-4" v-if="type === 'NOT_ACCOUNT'">
                        <!-- <v-card-actions class="pa-4" v-if="type === 'NOT_ACCOUNT'"> -->
                            <v-row>
                                <v-col cols="12">
                                    <v-file-input
                                        accept="image/*"
                                        label="Anexar Conta"
                                        @change="detectFiles($event)"
                                    ></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row class="text-center">
                                <v-col cols="6">
                                    <!-- <v-btn
                                        rounded
                                        text
                                        outlined
                                    >
                                        Enviar Depois
                                    </v-btn> -->
                                </v-col>
                                <v-spacer/>
                                <v-col cols="6">
                                    <v-btn
                                        color="primary"
                                        rounded
                                        @click="sendBill()"
                                    >
                                        Enviar Conta
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                        <!-- </v-card-actions> -->
                        <v-row class="pa-6">
                            <v-btn outlined block rounded @click="dialog_qualification_details = false" class="mt-6 mb-6 justify-center">
                                fechar
                            </v-btn>
                        </v-row>
                    </v-card>
                </v-row>
            </v-container>
        <!-- </v-layout> -->
    </v-dialog>
</template>
<script>
import { mapState } from 'vuex'
import { eventBus } from "../../main"

export default {
    props: ['uc', 'type'],
    mounted () {
        eventBus.$on('open-dialog_qualification_details', async () => {
            this.dialog_qualification_details = true
        })
    },
    computed: {
        ...mapState(['lead']),
        typeLabel() {
            if(this.type === 'qualified') {
                return {
                    label: 'Imóvel Qualificado e Aceito',
                    description: 'Após analise, identificamos que seu imóvel possui todos os requisitos para receber energia limpa.',
                    color: 'green',
                    icon: 'mdi-check'
                }
            } else if(this.type === 'NOT_ACCOUNT') {
                return {
                    label: 'Conta de Energia não encontrada',
                    description: '<p>Após analise, não conseguimos encontrar sua conta de luz no site da sua concessionária para que possamos determinar a quantidade de energia limpa que você necessita.</p> <p class="font-weight-bold">Por favor, anexe uma cópia da sua conta de luz</p>',
                    color: 'black',
                    icon: 'mdi-information'
                }
            } else if(this.type === 'NOT_QUALIFY') {
                return {
                    label: 'Imóvel Recusado',
                    description: '<p>Desculpe ;-(</p><p> Após analise, identificamos que o consumo médio anual desse imóvel é abaixo da média necessária para que possamos distribuir energia limpa compartilhada para a seu imóvel.</p> <p>Caso essas regras mudem avisaremos você no futuro. </p><p>Você também pode fazer uma nova tentativa no futuro caso seu consumo de energia seja maior.</p>',
                    color: 'red',
                    icon: 'mdi-alert-circle'
                }
            } else {
                return {
                    label: 'Ops',
                    description: 'Erro.',
                    icon: 'mdi-check'
                }
            }
        }
    },
    data: () => ({
        dialog_qualification_details: false
    })
}
</script>