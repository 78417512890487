import api from '../utils/api'
export const getCPFCemig = (cpf) => api.get(process.env.VUE_APP_GETCEMIG_API + '?cpf=' + cpf);

export const getQualification = (data) => {
    return api.post(process.env.VUE_APP_GETQUALIFICATION_API, data)
}

export const setQualification = (data) => {
    return api.post(process.env.VUE_APP_SETQUALIFICATION_API, data)
}
