var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "overflow-hidden",
      attrs: { flat: "", color: "#efefef", shaped: _vm.shaped ? true : false }
    },
    [
      _c(
        "v-row",
        { staticClass: "pa-3 mt-0", attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "2 pl-6" } },
            [
              _c(
                "v-btn",
                { attrs: { color: "#000000", outlined: "", rounded: "" } },
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.step))
                  ]),
                  _vm._v("/" + _vm._s(_vm.total))
                ]
              )
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "10" } }, [
            _c("div", { staticClass: "text-end pr-4 body-2" }, [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v("Você está em: ")
              ]),
              _vm._v(_vm._s(_vm.itens[0]))
            ]),
            _vm.itens.length > 1
              ? _c("div", { staticClass: "text-end pr-4  body-2" }, [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("Próximo: ")
                  ]),
                  _vm._v(_vm._s(_vm.itens[1]))
                ])
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }