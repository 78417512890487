var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _c(
        "v-card",
        {
          staticClass: "d-flex justify-center align-center mb-6",
          attrs: { flat: "", tile: "", height: "100vh" }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "pa-2",
              attrs: { color: "#FFF", outlined: "", flat: "", tile: "" }
            },
            [
              _c(
                "v-row",
                { staticClass: "justify-center pb-8" },
                [
                  _c("v-img", {
                    attrs: {
                      src: require("@/assets/novo/Logo-novo.svg"),
                      "max-height": "180",
                      "max-width": "200"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "justify-center" },
                [
                  _c("v-img", {
                    attrs: {
                      src: require("@/assets/cards/payment_confirm.png"),
                      "max-height": "300",
                      "max-width": "300"
                    }
                  })
                ],
                1
              ),
              _c("v-row", { staticClass: "justify-center text-center ma-3" }, [
                _c("h2", [
                  _vm._v("Método de pagamento cadastrado com sucesso!")
                ])
              ]),
              _c(
                "v-row",
                { staticClass: "justify-center text-center ma-12" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [_c("social-media")],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }