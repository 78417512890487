var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", {
            staticClass: "d-none d-md-block bg",
            attrs: { cols: "12", xs: "12", md: "6", lg: "4" }
          }),
          _c(
            "v-col",
            {
              staticClass: "pa-0 form",
              attrs: { cols: "12", md: "6", lg: "4" }
            },
            [
              _c("steps", {
                attrs: { step: 5, total: 5, itens: ["Fazer Portabilidade"] }
              }),
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "pa-2 pt-2 pb-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-6", attrs: { cols: "12" } },
                        [
                          _c("h2", [_vm._v("Confirme seus dados:")]),
                          _c("p", { staticClass: "pt-0 pb-0 mb-0" }, [
                            _vm._v("Nome: " + _vm._s(_vm.user.name))
                          ]),
                          _c("p", { staticClass: "pt-0 pb-0 mb-0" }, [
                            _vm._v("Telefone: " + _vm._s(_vm.user.phone))
                          ]),
                          _c("p", { staticClass: "pt-0 mt-0 mb-0" }, [
                            _vm._v("E-mail: " + _vm._s(_vm.user.email))
                          ]),
                          _c("p", [_vm._v("CPF: " + _vm._s(_vm.user.cpf))])
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "12" } },
                        [_c("v-divider")],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      staticClass: "ml-2 mr-2 pt-6",
                      attrs: { "lazy-validation": "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit($event)
                        }
                      },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pt-0 pb-0", attrs: { cols: "12" } },
                            [_c("h3", [_vm._v("Cadastrar uma senha")])]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pt-2 pb-0", attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Senha",
                                  type: _vm.show1 ? "text" : "password",
                                  "append-icon": _vm.show1
                                    ? "mdi-eye-off"
                                    : "mdi-eye",
                                  required: "",
                                  rules: _vm.passwordRules,
                                  filled: "",
                                  rounded: "",
                                  autofocus: "",
                                  dense: ""
                                },
                                on: {
                                  "click:append": function($event) {
                                    _vm.show1 = !_vm.show1
                                  }
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pt-0 pb-2", attrs: { cols: "12" } },
                            [
                              _c("ul", { staticClass: "caption" }, [
                                _c("li", [_vm._v("Mínimo de 8 caracteres")]),
                                _c("li", [
                                  _vm._v("Obrigatório letras e números")
                                ]),
                                _c("li", [
                                  _vm._v(
                                    "Obrigatório pelo menos 1 letra maiuscula"
                                  )
                                ]),
                                _c("li", [
                                  _vm._v(
                                    "Pelo menos um caracter especial [!@#$%]"
                                  )
                                ])
                              ])
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pt-2 pb-0", attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: _vm.show2 ? "text" : "password",
                                  "append-icon": _vm.show2
                                    ? "mdi-eye-off"
                                    : "mdi-eye",
                                  rules: _vm.confirm_passwordRules,
                                  label: "Confirmar Senha",
                                  placeholder: "###############",
                                  required: "",
                                  filled: "",
                                  rounded: "",
                                  dense: ""
                                },
                                on: {
                                  "click:append": function($event) {
                                    _vm.show2 = !_vm.show2
                                  }
                                },
                                model: {
                                  value: _vm.confirm_password,
                                  callback: function($$v) {
                                    _vm.confirm_password = $$v
                                  },
                                  expression: "confirm_password"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("v-divider")],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "pt-6 mt-3" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 mt-0",
                              attrs: { cols: "2", sm: "2", md: "2" }
                            },
                            [
                              _c("v-checkbox", {
                                staticClass: "pt-0 mt-0",
                                attrs: {
                                  type: "checkbox",
                                  required: "",
                                  rules: _vm.termoRules
                                },
                                on: {
                                  click: function($event) {
                                    _vm.dialog = true
                                  }
                                },
                                model: {
                                  value: _vm.termouso,
                                  callback: function($$v) {
                                    _vm.termouso = $$v
                                  },
                                  expression: "termouso"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-6 mt-0",
                              attrs: { cols: "10", sm: "10", md: "10" }
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  " Concluindo a migração você declara que aceita o "
                                ),
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        _vm.dialog = true
                                      }
                                    }
                                  },
                                  [_vm._v("termos de uso e termo de adesão")]
                                )
                              ])
                            ]
                          ),
                          _vm.lead &&
                          _vm.lead.coupon &&
                          _vm.lead.coupon.coupon === "FUNDACAOSARA"
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 mt-0",
                                  attrs: { cols: "2", sm: "2", md: "2" }
                                },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "pt-0 mt-0",
                                    attrs: { type: "checkbox", required: "" },
                                    model: {
                                      value: _vm.donation,
                                      callback: function($$v) {
                                        _vm.donation = $$v
                                      },
                                      expression: "donation"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.lead &&
                          _vm.lead.coupon &&
                          _vm.lead.coupon.coupon === "FUNDACAOSARA"
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-6 mt-0",
                                  attrs: { cols: "10", sm: "10", md: "10" }
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      " Concordo em destinar à Fundação Sara o desconto mensal concedido em minha fatura de energia limpa para ações em prol de crianças com câncer. "
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.error_message
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center red--text",
                                  attrs: { cols: "12" }
                                },
                                [_c("p", [_vm._v(_vm._s(_vm.error_message))])]
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        large: "",
                                        href:
                                          "https://api.whatsapp.com/send/?phone=5535998625580&text=Ol%C3%A1!",
                                        target: "_blank",
                                        dark: "",
                                        rounded: "",
                                        block: "",
                                        color: "green",
                                        disabled: _vm.loading
                                      }
                                    },
                                    [_vm._v("Falar com um consultor")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "text-none",
                                  attrs: {
                                    color: "secondary",
                                    block: "",
                                    rounded: "",
                                    large: "",
                                    loading: _vm.loading,
                                    disabled: !_vm.valid,
                                    type: "submit"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.submit($event)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.error_message
                                        ? "Tentar novamente"
                                        : "Fazer a portabilidade agora"
                                    ) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("qualification-detail", {
                attrs: { type: _vm.qualification_type, uc: _vm.uc }
              })
            ],
            1
          ),
          _c("v-col", {
            staticClass: "d-none d-md-block bg",
            attrs: { cols: "12", xs: "12", md: "6", lg: "4" }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", "max-width": "600px" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "mx-auto my-12" },
            [
              _c(
                "v-card-text",
                {
                  staticClass: "ma-0 pa-0",
                  staticStyle: { height: "500px", width: "100%" }
                },
                [
                  _c("cover", { staticClass: "ma-0 pa-0" }),
                  _c("terms-of-use", { staticClass: "ma-0 pa-5" })
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: { click: _vm.reject }
                    },
                    [_vm._v(" Fechar ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pa-5",
                      attrs: { color: "primary", rounded: "" },
                      on: { click: _vm.accept }
                    },
                    [_vm._v(" Aceitar ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.color, top: "", dark: "" },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _c("span", { staticClass: "mr-auto" }, [
            _vm._v(_vm._s(_vm.snackMessage))
          ]),
          _c(
            "v-icon",
            {
              staticClass: "ml-3",
              attrs: { size: "16" },
              on: {
                click: function($event) {
                  _vm.snackbar = false
                }
              }
            },
            [_vm._v(" mdi-close-circle ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }