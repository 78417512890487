var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto mt-2 px-4", attrs: { outlined: "", rounded: "" } },
    [
      _c(
        "v-list-item",
        { attrs: { "three-line": "" } },
        [
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", { staticClass: "headline mb-1" }, [
                _vm._v(" " + _vm._s(_vm.qualification.consumer_unit) + " ")
              ]),
              _c("v-list-item-subtitle", [
                _vm._v("Imóvel Qualificado e Aceito")
              ])
            ],
            1
          ),
          _c(
            "v-list-item-avatar",
            { attrs: { tile: "", size: "40" } },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mt-4",
                  attrs: { "x-large": "", color: "green lighten-1" }
                },
                [_vm._v("mdi-check")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }