<template>
    <v-container fluid class="ma-0">
        <v-row>
            <v-col cols="12" xs="12" md="6" lg="4" class="d-none d-md-block bg"/>
            <v-col cols="12" md="6" lg="4" class="pa-0 form">
                <steps :step="5" :total="5" :itens="['Fazer Portabilidade']"/>
                <v-container>
                    <v-row class="pa-2 pt-2 pb-0">
                        <v-col cols="12" class="pt-6">
                            <h2>Confirme seus dados:</h2>
                            <p class="pt-0 pb-0 mb-0">Nome: {{user.name}}</p>
                            <p class="pt-0 pb-0 mb-0">Telefone: {{user.phone}}</p>
                            <p class="pt-0 mt-0 mb-0">E-mail: {{user.email}}</p>
                            <p>CPF: {{ user.cpf}}</p>
                        </v-col>
                        <v-col cols="12" class="pt-0">
                            <v-divider/>
                        </v-col>
                    </v-row>
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        @submit.prevent="submit"
                        class="ml-2 mr-2 pt-6"
                    >
                        <v-row>
                            <v-col cols="12" 
                                class="pt-0 pb-0"
                            >
                                <h3>Cadastrar uma senha</h3>
                            </v-col>
                            <v-col cols="12" 
                                class="pt-2 pb-0"
                            >
                                <v-text-field
                                    v-model="password"
                                    label="Senha"
                                    :type="show1 ? 'text' : 'password'"
                                    :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                                    required
                                    :rules="passwordRules"
                                    filled
                                    rounded
                                    autofocus
                                    dense
                                    @click:append="show1 = !show1"
                                ></v-text-field>
                            </v-col>
                            <v-col 
                                cols="12" 
                                class="pt-0 pb-2"
                            >
                                <ul class="caption">
                                    <li>Mínimo de 8 caracteres</li>
                                    <li>Obrigatório letras e números</li>
                                    <li>Obrigatório pelo menos 1 letra maiuscula</li>
                                    <li>Pelo menos um caracter especial [!@#$%]</li> 
                                </ul>
                            </v-col>
                            <v-col cols="12" class="pt-2 pb-0">
                                <v-text-field
                                    :type="show2 ? 'text' : 'password'"
                                    :append-icon="show2 ? 'mdi-eye-off' : 'mdi-eye'"
                                    v-model="confirm_password"
                                    :rules="confirm_passwordRules"
                                    label="Confirmar Senha"
                                    placeholder="###############"
                                    required
                                    filled
                                    rounded
                                    dense
                                    @click:append="show2 = !show2"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-divider/>
                            </v-col>
                        </v-row>
                        <v-row class="pt-6 mt-3">
                            <v-col
                                cols="2"
                                sm="2"
                                md="2"
                                class="pt-0 mt-0"
                            >
                                <v-checkbox
                                    v-model="termouso"
                                    type="checkbox"
                                    required
                                    :rules="termoRules"
                                    class="pt-0 mt-0"
                                    @click="dialog=true"
                                ></v-checkbox>
                            </v-col>
                            <v-col
                                cols="10"
                                sm="10"
                                md="10"
                                class="pt-0 pb-6 mt-0"
                            >
                                <div>
                                    Concluindo a migração você declara que aceita o <a href='#' @click.prevent='dialog = true'>termos de uso e termo de adesão</a>
                                </div>
                            </v-col>
                            <v-col
                                cols="2"
                                sm="2"
                                md="2"
                                class="pt-0 mt-0"
                                v-if="lead && lead.coupon && lead.coupon.coupon === 'FUNDACAOSARA'"
                            >
                                <v-checkbox
                                    v-model="donation"
                                    type="checkbox"
                                    required
                                    class="pt-0 mt-0"
                                ></v-checkbox>
                            </v-col>
                            <v-col
                                cols="10"
                                sm="10"
                                md="10"
                                class="pt-0 pb-6 mt-0"
                                v-if="lead && lead.coupon && lead.coupon.coupon === 'FUNDACAOSARA'"
                            >
                                <div>
                                    Concordo em destinar à Fundação Sara o desconto mensal concedido em minha fatura de energia limpa para ações em prol de crianças com câncer.
                                </div>
                            </v-col>
                        </v-row>
                        <v-row v-if="error_message">
                            <v-col cols="12" class="text-center red--text">
                                <p>{{error_message}}</p>
                            </v-col>
                            <v-col cols="12">
                                <v-btn large href="https://api.whatsapp.com/send/?phone=5535998625580&text=Ol%C3%A1!" target="_blank" dark rounded block color="green" :disabled="loading">Falar com um consultor</v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-btn
                                    color="secondary"
                                    block
                                    rounded
                                    large
                                    :loading="loading"
                                    :disabled="!valid"  
                                    @click.prevent="submit"
                                    type="submit"
                                    class="text-none"
                                    >{{error_message ? 'Tentar novamente' : 'Fazer a portabilidade agora'}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
                <qualification-detail :type="qualification_type" :uc="uc"/>
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4" class="d-none d-md-block bg"/>
        </v-row>
        <v-dialog
            v-model="dialog"
            scrollable
            max-width="600px"
        >
            <v-card class="mx-auto my-12">
                
                <v-card-text style="height: 500px; width: 100%;" class="ma-0 pa-0">
                    <cover class="ma-0 pa-0"/>
                    <terms-of-use  class="ma-0 pa-5"/>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="reject"
                    >
                        Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        rounded
                        @click="accept"
                        class="pa-5"
                    >
                        Aceitar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar :color="color" top v-model="snackbar" dark>
            <span class="mr-auto">{{ snackMessage }}</span>
            <v-icon size="16" @click="snackbar = false" class="ml-3"> mdi-close-circle </v-icon>
        </v-snackbar>
    </v-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'

import QualificationDetail from '../components/Qualification/QualificationDetail.vue'
import TermsOfUse from '../components/Contract/TermsOfUse.vue'
import Cover from '../components/Contract/Cover.vue'
import Steps from '../components/Steps.vue'
import { getIP } from '../services/getIP'
import { updateUsedCoupon } from '../services/coupon'
import { convertRDStation, setWinOpportunity } from '../services/rdstation'
import { finishQualification } from "../services/qualification"
import { firestore } from "../firebase/firestore";

import { eventBus } from '../main'

export default {
    components: {
        Steps,
        QualificationDetail,
        TermsOfUse,
        Cover
    },
    async mounted() {
        if(!this.user) {
            this.$router.push({path: '/', query: this.$route.query})
            return null
        }

        if(this.user && !this.qualifications) {
            this.loading = true
            this.getQualification(this.user.cpf)
                .then(() => {
                    this.loading = false
                })
                .catch(err => {
                    console.error(err)
                    this.loading = false
                })
        }
        if(this.user && this.user.plan && !this.plan) {
            await this.getPlan(this.user.plan.id)
        }
        if(this.lead && this.lead.coupon && this.lead.coupon.coupon === 'FUNDACAOSARA') this.donation = true
        this.$gtm.trackEvent({
            event: 'step6_assinatura',
            category: "onboard",
            action: "pageview",
            label: "Acessou pagina de adicionar senha e termos de uso",
            noninteraction: false, // Optional
            step: this.$route.name,
            email: this.user?.email || this.lead?.email,
            phone: this.user?.phone || this.lead?.phone,
            person_type: this.user?.person_type || this.lead?.person_type,
            value: this.user?.average_consumption || this.lead?.average_consumption
        });
        convertRDStation('step6_assinatura', this.lead)
            .catch(err => console.error('Erro ao gravar a conversão no RD Station. Erro: ', err))
            .then(() => console.log('Conversão step6_assinatura gravada com sucesso no RD Station'))
    },
    data() {
        return {
            loading: false,
            password: null,
            passwordRules: [ 
                v => !!v || 'Informe a senha', 
                v => (v && v.length >= 8) || 'A senha deve conter no minimo 8 caracters',
                v => /(?=.*[A-Z])/.test(v) || 'Precisa adicionar ao menos um caracter maiusculo', 
                v => /(?=.*\d)/.test(v) || 'Precisa ter pelo menos um números', 
                v => /([!@$%#])/.test(v) || 'Precisa ter pelo menos um caracter especial [!@#$%]' 
            ],
            confirm_password: null,
            confirm_passwordRules: [
                v => !!v || "Confirme sua sennha",
                v => (v === this.password || 'Ops. Senhas não conferem')
            ],
            termouso: null,
            donation: false,
            termoRules: [
                v => !!v || "Obrigatório o aceite do contrato para adesão",
                v => v && v === true || "Ops. aceite os termos para seguir"
            ],
            valid: false,
            qualification_type: '',
            uc: '',
            image: null,
            dialog: false,
            error_message: null,
            show1: false,
            show2: false,
            color: '',
            snackMessage: '',
            snackbar: false
        }
    },
    computed: {
        ...mapState(['lead', 'user', 'qualifications']),
    },
    methods: {
        ...mapActions(['getQualification', 'getLocalUser', 'getLocalLead', 'updatePassword', 'saveLead', 'saveUser', 'getPlan']),
        async submit() {
            this.error_message = null
            this.loading = true
            let ipinfo = null
            try {
                ipinfo = await getIP()
            } catch(err) {
                console.error('Erro ao buscar endereço IP', err)
            }
                let _user = {
                    id: this.user.id,
                    termouso: true,
                    percentage_discount: this.user?.plan?.discount,
                    signedcontract_ipinfo: ipinfo ? ipinfo.data : null,
                    signedcontract_at: new Date(),
                    signedcontract_by: 'onboarding',
                    signedcontract_source: 'online',
                    signedcontract_partner: 'enercred',
                    donation: this.donation,
                    step: this.$route.name,
                }

                if (!this.user?.status == "DESQUALIFICAR") {
                    this._user.status = "QUALIFICAR"
                }
                if(this.lead.partner && this.lead.partner !== '') _user.signedcontract_partner = this.lead.partner
                else if(this.user.partner  && this.user.partner !== '') _user.signedcontract_partner = this.user.partner

                this.saveLead(_user)
                this.saveUser(_user)
                    .then(async () => {
                        // Se o cupom possuir planta especifica qualifica com a planta correta
                        let power_plant_qualification = null
                        if (this.user?.coupon?.power_plant_id) {
                            power_plant_qualification = this.user?.coupon?.power_plant_id
                        }

                        // Se o cupom possuir percentual de desconto persolalizado qualifica com o percentual de desconto do cupom
                        let percentage_discount_qualification = null
                        if (this.user?.coupon?.percentage_discount) {
                            percentage_discount_qualification = this.user?.coupon?.percentage_discount
                        }

                        const qualification_ids = this?.qualifications?.map(item => item.id)

                        if(this.qualifications?.length > 0) {
                            await finishQualification(
                                this.user?.cpf,
                                this.user?.id,
                                qualification_ids,
                                percentage_discount_qualification,
                                null,
                                power_plant_qualification,
                                this.user?.plan,
                                null,
                                this.user?.utility_company,
                                this.user?.email
                                )
                                .then((ret) => {
                                    if (ret.status === 200) {
                                        if(!ret?.data?.results?.some(item => item.error)){
                                            firestore.collection("users").doc(this.user?.id).update({status: "ATIVO"});
                                        }
                                        // Se o cliente colocou um Cupom de desconto valido, cria evento no Google Tag Manager
                                        if (this.user?.coupon) {
                                            //Marca como venda no RDStation
                                            setWinOpportunity({
                                                event: "SALE",
                                                email: this.user?.email,
                                                sales_value: this.user?.average_consumption,
                                            })
                                            .then(() => {
                                                console.log("Marcado como venda realizada no RDStation")
                                            })
                                            .catch((err) => {
                                                console.error(err)
                                            })
    
                                            // Atualiza a quantidade de uso do Cupon
                                            updateUsedCoupon(this.user?.coupon?.id)
                                        }
                                    } else if (ret.data.error === 'Esta UC já existe, e se encontra com status activated') {
                                        this.snack('Esta Unidade Consumidora já existe com status ATIVO.', 'red')
                                        this.loading = false
                                    } else {
                                        this.snack('Erro ao finalizar cadastro.', 'red')
                                        this.loading = false
                                        console.error(ret)
                                    }
                                })
                                .catch((err) => {
                                    this.snack('Erro ao finalizar cadastro.', 'red')
                                    this.loading = false
                                    console.error(err)
                                    this.qualificationMessage = ' Ainda é necessário qualificar a(s) Unidade(s) Consumidora(s).'
                                })

                                this.save()
                                this.loading = false
                        } else {
                            this.loading = false
                            this.save()
                        }
                    })
                    .catch(err => {
                        console.error(err)
                        this.error_message = 'Ops! Erro ao finalizar o cadastro'
                        this.loading = false
                    })
        },
        snack (message, color) {
            this.color = color
            this.snackMessage = message
            this.snackbar = true
        },
        openQualificationDetail(uc, type) {
            this.uc = uc,
            this.qualification_type = type,
            eventBus.$emit('open-dialog_qualification_details')
        },
        validPassword(value) {
            return value === this.password
        },
        accept() {
            this.dialog = false
            this.termouso = true
            this.$gtm.trackEvent({
                event: 'step6_aceite_dos_termos',
                category: "onboard",
                action: "click",
                label: "Acessou pagina de validação de qualificação",
                noninteraction: false, // Optional
                step: this.$route.name,
                email: this.user?.email || this.lead?.email,
                phone: this.user?.phone || this.lead?.phone,
                person_type: this.user?.person_type || this.lead?.person_type,
                value: this.user?.average_consumption || this.lead?.average_consumption,
            });
            convertRDStation('step6_aceite_dos_termos', this.lead)
                .catch(err => console.error('Erro ao gravar a conversão no RD Station. Erro: ', err))
                .then(() => console.log('Conversão step6_assinatura gravada com sucesso no RD Station'))
        },
        reject() {
            this.dialog = false
            this.termouso = false
        },
        save() {
            // Soma todas as mensalidades dos imóveis qualificados
            let totalsign = this.qualifications.reduce((sum, cur) => {
                if(cur.status === 'qualified' && cur.consumer_unit_detail && cur.consumer_unit_detail.mensalidade) return sum + cur.consumer_unit_detail.mensalidade
                else return sum
            }, 0)
            let total = this.user.average_consumption

            // Se identificou os imóveis qualificados, então grava no GTM o valor qualificado,
            // caso contrario, grava a média informada pelo membro no momento de preenchimento.
            if(totalsign && totalsign > 0) total = totalsign


            // Salva venda no Gooogle Tagle Manager
            this.$gtm.trackEvent({
                event: 'assinar',
                category: "onboard",
                action: "click",
                label: "Assinatura do serviço do onboard web",
                order_id: this.user.id,
                email: this.user.email,
                phone: this.user.phone,
                value: total,
                noninteraction: false, // Optional
            });


            // Se o cliente colocou um Cupom de desconto valido, cria evento no Google Tag Manager
            if(this.lead.coupon && this.lead.coupon.discount) {

                this.$gtm.trackEvent({
                    event: 'cupom',
                    category: "onboard",
                    action: "click",
                    label: "Cliente adicionou o cupom",
                    value: this.lead.coupon.discount,
                    cupom: this.lead.coupon.coupon,
                    email: this.lead.email,
                    phone: this.lead.phone,
                    noninteraction: false, // Optional
                });


                
                // Atualiza a quantidade de uso do Cupon
                if(this.lead.coupon.id) updateUsedCoupon(this.lead.coupon.id)
            }

            this.updatePassword(this.password)
                .catch(err => {
                    console.error(err)
                })

            this.loading = false
            this.$router.push({path: '/bem-vinde', query: this.$route.query})
        }
    }
}
</script>