var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", {
            staticClass: "d-none d-md-block bg",
            attrs: { cols: "12", xs: "12", md: "6", lg: "4" }
          }),
          _c(
            "v-col",
            {
              staticClass: "pa-0 form",
              attrs: { cols: "12", md: "6", lg: "4" }
            },
            [
              _c("v-col", { staticClass: "text-center pb-0" }, [
                _c("p", { staticClass: "mb-0 text-center" }, [
                  _vm._v("Olá, " + _vm._s(_vm.user.name) + "!")
                ])
              ]),
              _c("card-form")
            ],
            1
          ),
          _c("v-col", {
            staticClass: "d-none d-md-block bg",
            attrs: { cols: "12", xs: "12", md: "6", lg: "4" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }