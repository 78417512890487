<template>
  <v-container fluid class="pa-0">
    <v-row class="pa-0 home">
      <v-img :src="landingPageConfig && landingPageConfig.background ? landingPageConfig.background : require('@/assets/usina-bg2.jpg')" fluid class="ma-0 justify-center align-center pb-10">
        <v-row>
          <v-col cols="12" xs="12" md="6" lg="6">
            <v-container pl-md-8 pl-lg-16>
              <div class="pa-12 pl-4 pt-xs-2 pt-md-0 ma-0">
                <v-img src="@/assets/novo/juntos_white_600px.png" width="280px"/>
              </div>
              <v-layout row class="banner">
                <banner-simulador :config="landingPageConfig"/>
              </v-layout>
            </v-container>
          </v-col>
          <v-col cols="12" md="6" lg="6" class="pa-4" style="background-color: transparent"  id="simular">
              <v-container>
                <v-layout row class="banner">
                  <consumption-form/>
                  <v-dialog
                      v-model="dialog_lead_form"
                      fullscreen
                      hide-overlay
                      transition="dialog-bottom-transition"
                      scrollable
                  >
                      <lead-form/>
                  </v-dialog>
                </v-layout>
              </v-container>
            </v-col>
        </v-row>
      </v-img>
    </v-row>
    aaaa
    <how-works :config="landingPageConfig"/>
    <our-members :config="landingPageConfig" class="ourmembers"/>
    <about-enercred :config="landingPageConfig" class="d-none d-md-block"/>
    <about-enercred-mobile :config="landingPageConfig" class="d-md-none"/>
  </v-container>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import BannerSimulador from '../components/lp/BannerSimulador.vue'
import ConsumptionForm from '../components/Simulador/ConsumptionForm.vue'
import HowWorks from '../components/lp/HowWorks.vue'
import OurMembers from '../components/lp/OurMembers.vue'
import AboutEnercred from '../components/lp/AboutEnercred.vue'
import AboutEnercredMobile from '../components/lp/AboutEnercredMobile.vue'

export default {
    components: {
        BannerSimulador,
        ConsumptionForm,
        HowWorks,
        OurMembers,
        AboutEnercred,
        AboutEnercredMobile,
    },
    async created() {
      // console.log('landingPageConfig: ', this.landingPageConfig)
      // var eventProperties = {
      //     "page": this.$route.name
      // };
      // this.$amplitude.logEvent('VIEWED', eventProperties);
    },
    data() {
        return {
            dialog_lead_form: false,
        }
    },
    computed: {
      ...mapState(['landingPageConfig'])
    },
    methods: {
      ...mapActions(['getLead'])
    }
}
</script>
<style scoped>
  .banner {
    color: white;
    align-content: center;
    align-content: space-around;
  }
  .home {
    background-size: cover;
  }
  .ourmembers {
    background-color: '#7D3DFF';
  }
</style>