var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "text-center",
                  attrs: { cols: "12", sm: "12", md: "12" }
                },
                [_c("h2", [_vm._v("Validação")])]
              )
            ],
            1
          ),
          _c(
            "v-form",
            {
              ref: "form",
              staticClass: "ml-3 pr-6 mr-3",
              attrs: { "lazy-validation": "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submit($event)
                }
              },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              !_vm.code_validation
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-left",
                          attrs: { cols: "12", sm: "12", md: "12" }
                        },
                        [
                          _vm._v(
                            " Para garantirmos uma comunicação efetiva com você precisamos validar seu celular ou e-mail, escolha o meio que deseja receber nosso código de validação "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.code_validation
                ? _c(
                    "v-row",
                    { staticClass: "pt-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "6", sm: "6", md: "6" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-2",
                              attrs: {
                                large: "",
                                rounded: "",
                                block: "",
                                outlined: "",
                                small: "",
                                color: "black",
                                disabled: _vm.loading
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.submit("sms")
                                }
                              }
                            },
                            [
                              _vm._v(" SMS "),
                              _c("v-icon", { attrs: { right: "" } }, [
                                _vm._v("mdi-cellphone-message")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "6", sm: "6", md: "6" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-2",
                              attrs: {
                                large: "",
                                rounded: "",
                                block: "",
                                outlined: "",
                                small: "",
                                color: "black",
                                disabled: _vm.loading
                              },
                              on: {
                                click: function($event) {
                                  return _vm.submit("email")
                                }
                              }
                            },
                            [
                              _vm._v(" E-mail "),
                              _c("v-icon", { attrs: { right: "" } }, [
                                _vm._v("mdi-email-check-outline")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.error_message
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12 pl-9" } },
                            [
                              _c(
                                "p",
                                { staticClass: "red--text body-1 text-center" },
                                [
                                  _vm._v(
                                    "Ops! " + _vm._s(this.error_message) + "!"
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    href:
                                      "https://api.whatsapp.com/send/?phone=5535998625580&text=Ol%C3%A1!",
                                    target: "_blank",
                                    outlined: "",
                                    rounded: "",
                                    block: "",
                                    color: "green",
                                    disabled: _vm.loading
                                  }
                                },
                                [_vm._v("Falar com um consultor")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.code_validation
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "12", sm: "12", md: "12" }
                        },
                        [
                          _vm._v(
                            " Informe o código enviado ao seu " +
                              _vm._s(
                                _vm.code_validation === "sms"
                                  ? "celular " + _vm.lead.phone
                                  : "e-mail " + _vm.lead.email
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "8", sm: "8", md: "8" }
                        },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "######",
                                expression: "'######'"
                              }
                            ],
                            attrs: {
                              label: "Código de Validação",
                              required: "",
                              rules: _vm.codeRules,
                              filled: "",
                              rounded: "",
                              dense: ""
                            },
                            model: {
                              value: _vm.code,
                              callback: function($$v) {
                                _vm.code = $$v
                              },
                              expression: "code"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "4", sm: "4", md: "4" }
                        },
                        [
                          _vm.countDown <= 0
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mt-2 ml-0",
                                  attrs: {
                                    large: "",
                                    rounded: "",
                                    block: "",
                                    plain: "",
                                    loading: _vm.loading
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.code_validation = null
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("mdi-reload")
                                  ]),
                                  _vm._v(" Reenviar ")
                                ],
                                1
                              )
                            : _c("span", [
                                _vm._v(
                                  "Reenviar em " + _vm._s(_vm.countDown) + "s"
                                )
                              ])
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 text-center",
                          attrs: { cols: "12", sm: "12", md: "12" }
                        },
                        [
                          _vm.error_message
                            ? _c(
                                "p",
                                { staticClass: "body-1 red--text text-center" },
                                [_vm._v(_vm._s(_vm.error_message))]
                              )
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-2",
                              attrs: {
                                large: "",
                                rounded: "",
                                block: "",
                                color: "primary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.validation()
                                }
                              }
                            },
                            [_vm._v(" Validar ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }