var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-0 ma-0" },
    [
      _vm.plan.id == "liquidaenergiapj" || _vm.plan.id == "liquidaenergiapf"
        ? _c(
            "div",
            [
              _c("v-img", {
                attrs: {
                  src: require("@/assets/contracts/capa-juntos-11.png"),
                  width: "100%"
                }
              }),
              _c("div", { staticClass: "desc" }, [
                _c("p", { staticClass: "my-2" }, [
                  _vm._v(
                    _vm._s(
                      _vm.plan.cancelation_conditions.contract_canceled_clause
                    )
                  )
                ]),
                _c("p", { staticClass: "my-2" }, [
                  _vm._v(
                    _vm._s(
                      _vm.plan.cancelation_conditions.contract_canceled_clause2
                    )
                  )
                ])
              ]),
              _c("v-img", {
                attrs: {
                  src: require("@/assets/contracts/capa-juntos-12.png"),
                  width: "100%"
                }
              })
            ],
            1
          )
        : _c("v-img", {
            attrs: {
              src: require("@/assets/contracts/capa-juntos-10.png"),
              width: "100%"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }