import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { VueMaskDirective } from 'v-mask'

import VueGtm from '@gtm-support/vue2-gtm';
import VueRouter from 'vue-router'
import router from './router'
import store from './store'
import api from "./utils/api"


import { firebaseAuth } from './firebase/firebaseAuth'

Vue.$http = api;
api.defaults.timeout = 15000;
api.defaults.headers.get['Accepts'] = 'application/json'
api.defaults.headers.get['Access-Control-Allow-Origin'] = '*'

api.interceptors.request.use(
  async config => {
    let token = store.state.token || localStorage.getItem("token");
    if (token) {
      config.headers.common["Authorization"] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    console.error('error: ', error)
    return Promise.reject(error);
  }
);

let loop = 0;
let isRefreshing = false;
let subscribers = [];

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

function onRefreshed(token) {
  subscribers.map((cb) => cb(token));
}

api.interceptors.response.use(undefined, (err) => {
  const {
    config,
    // data: { data }
  } = err
  const originalRequest = config;
  if (err.name && err.name === 'Error' && err.message && err.message === 'Network Error') return Promise.reject(err);
  if ((err.response && err.response.status === 400 && err.response.data && (err.response.data.code === "auth/id-token-expired" || err.response.data === "Token não informado" || err.response.data.code === "auth/argument-error") && loop < 1) || err.response.status === 403) {
    loop++;
    if (!isRefreshing) {
      isRefreshing = true;
      if (firebaseAuth.currentUser) {
        firebaseAuth.currentUser.getIdToken(true)
          .then(idToken => {
            const now = new Date()
            const expirationDate = new Date(now.getTime() + (3600000)) //Configura o logout para daqui 1 hora
            localStorage.setItem('token', idToken)
            store.commit('setToken', idToken)
            localStorage.setItem('expirationDate', expirationDate)
            isRefreshing = false;
            onRefreshed(idToken);
            subscribers = [];
          })
      } else {
        firebaseAuth.signInAnonymously()
          .then(res => {
            let token = res.user.za
            if (token) localStorage.setItem('token', token)
          })
      }
    }

    return new Promise((resolve) => {
      subscribeTokenRefresh((token) => {
        originalRequest.headers.Authorization = `Bearer ${token}`;
        resolve(api(originalRequest));
      });
    });
  } else if (err.response && (err.response.status === 200 || err.response.status === 201)) {
    return Promise.resolve({ status: err.response.status, data: err.response.data });
  } else if (err.response && err.response.status === 404) {
    return Promise.resolve({ status: err.response.status, data: err.response.data });
  }
  return Promise.reject(err);
});

Vue.use(VueRouter)
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);

Vue.use(VueGtm, {
  id: 'GTM-MNL8PG8', // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  // queryParams: {
  //   // Add url query string when load gtm.js with GTM ID (optional)
  //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
  //   gtm_preview: 'env-4',
  //   gtm_cookies_win: 'x'
  // },
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ['homepage'], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
});

Vue.directive('mask', VueMaskDirective);
Vue.config.productionTip = false

import { firebaseApp } from './firebase/firebase'
import "./utils/filters"

export const eventBus = new Vue();
let app

// const amplitudeApiKey = process.env.VUE_APP_AMPLITUDE_APIKEY
// import VueAmplitude from 'vue-amplitude';
// Vue.use(VueAmplitude, { apiKey: amplitudeApiKey });

firebaseApp.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
});