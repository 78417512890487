var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-8 pb-8", attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-row",
        { staticClass: "ourmembers", attrs: { align: "center" } },
        [
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "12", lg: "12" } }, [
            _c("div", { staticClass: "pa-12" }, [
              _c("h1", { staticStyle: { color: "#FFF" } }, [
                _vm._v(
                  "Faça parte dessa comunidade que economiza e ajuda o planeta"
                )
              ]),
              _c(
                "h2",
                {
                  staticClass:
                    "font-weight-light blue--text pt-8 d-none d-lg-block"
                },
                [
                  _vm._v(
                    "Orgulho de como nossos membros estão construindo um futuro mais sustentável economicamente, ambientalmente e socialmente."
                  )
                ]
              )
            ])
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "4", lg: "4" } }, [
            _c("iframe", {
              attrs: {
                width: "100%",
                height: "220",
                src:
                  "https://www.youtube.com/embed/0PVzZVgoolM?enablejsapi=1&origin=https%3A%2F%2Fenercred.com.br",
                frameborder: "0",
                allow:
                  "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
                allowfullscreen: "",
                "data-gtm-yt-inspected-30051236_3": "true",
                id: "453571096",
                "data-gtm-yt-inspected-1_25": "true"
              }
            })
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "4", lg: "4" } }, [
            _c("iframe", {
              attrs: {
                width: "100%",
                height: "220",
                src:
                  "https://www.youtube.com/embed/aTF-JUDkk08?enablejsapi=1&origin=https%3A%2F%2Fenercred.com.br",
                frameborder: "0",
                allow:
                  "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
                allowfullscreen: "",
                "data-gtm-yt-inspected-30051236_3": "true",
                id: "481339508",
                "data-gtm-yt-inspected-1_25": "true"
              }
            })
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "4", lg: "4" } }, [
            _c("iframe", {
              attrs: {
                width: "100%",
                height: "220",
                src:
                  "https://www.youtube.com/embed/n_o0frzSyaE?enablejsapi=1&origin=https%3A%2F%2Fenercred.com.br",
                frameborder: "0",
                allow:
                  "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
                allowfullscreen: "",
                "data-gtm-yt-inspected-30051236_3": "true",
                id: "298303397",
                "data-gtm-yt-inspected-1_25": "true"
              }
            })
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "12", lg: "12" } }, [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "pl-16 pr-16",
                    attrs: {
                      outlined: "",
                      color: "white",
                      href: "#simular",
                      large: "",
                      rounded: ""
                    }
                  },
                  [_vm._v(_vm._s(_vm.config.call_to_action || "SIMULE AGORA"))]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }