var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", {
            staticClass: "d-none d-md-block bg",
            attrs: { cols: "12", xs: "12", md: "6", lg: "4" }
          }),
          _c(
            "v-col",
            {
              staticClass: "pa-0 form",
              attrs: { cols: "12", md: "6", lg: "4" }
            },
            [
              _c("div", { staticClass: "d-flex justify-center pt-4" }, [
                _c(
                  "div",
                  [
                    _c("v-img", {
                      attrs: {
                        src: require("@/assets/novo/logo_roxo.svg"),
                        width: "200px"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("benefits", {
                attrs: { discount: _vm.discount },
                on: { udpate_discount: _vm.onUpdateDiscount }
              }),
              _c(
                "v-container",
                [_c("v-row", { staticClass: "ml-0 mr-0 pb-2" })],
                1
              ),
              _vm.subscribe
                ? _c("steps", {
                    staticClass: "mt-3",
                    attrs: { step: 1, total: 5, itens: _vm.steps_item }
                  })
                : _vm._e(),
              _c(
                "v-container",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.subscribe,
                      expression: "subscribe"
                    }
                  ],
                  staticStyle: { background: "#FFF" }
                },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      staticClass: "ml-2 mr-2 mt-6",
                      attrs: { "lazy-validation": "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit($event)
                        }
                      },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "", "pt-0": "" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-h7 font-weight-bold black--text text-center pb-6"
                            },
                            [
                              _vm._v(
                                "Digite o " +
                                  _vm._s(
                                    _vm.lead.person_type === "pf"
                                      ? "CPF"
                                      : "CNPJ"
                                  ) +
                                  " do titular da conta"
                              )
                            ]
                          ),
                          _c(
                            "v-row",
                            { staticClass: "pt-4" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0",
                                  attrs: { cols: "12", sm: "12", md: "12" }
                                },
                                [
                                  _vm.plans.length > 1
                                    ? _c("v-combobox", {
                                        attrs: {
                                          value: _vm.plan,
                                          items: _vm.plans,
                                          "item-text": "name",
                                          label: "Planos",
                                          outlined: "",
                                          rounded: "",
                                          dense: ""
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.updatePlan($event)
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value:
                                          _vm.lead.person_type === "pf"
                                            ? "###.###.###-##"
                                            : "##.###.###/####-##",
                                        expression:
                                          "lead.person_type === 'pf' ? '###.###.###-##' : '##.###.###/####-##'"
                                      }
                                    ],
                                    attrs: {
                                      id: "cpf",
                                      autofocus: "",
                                      label:
                                        _vm.lead.person_type === "pf"
                                          ? "CPF"
                                          : "CNPJ",
                                      rules:
                                        _vm.lead.person_type === "pf"
                                          ? _vm.cpfRules
                                          : _vm.cnpjRules
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.clearValid($event)
                                      }
                                    },
                                    model: {
                                      value: _vm.cpf,
                                      callback: function($$v) {
                                        _vm.cpf = $$v
                                      },
                                      expression: "cpf"
                                    }
                                  }),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "caption font-italic pt-4 info--text"
                                    },
                                    [
                                      _vm._v(
                                        "Conforme a LGPD, utilizaremos o seu " +
                                          _vm._s(
                                            _vm.lead.person_type === "pj"
                                              ? "CNPJ"
                                              : "CPF"
                                          ) +
                                          " apenas para a verificação de compatibilidade e eventual portabilidade"
                                      )
                                    ]
                                  ),
                                  _vm.lead.person_type === "pj"
                                    ? _c("v-text-field", {
                                        attrs: {
                                          label: "Razão Social",
                                          type: "text",
                                          required: "",
                                          rules: [
                                            function(v) {
                                              return (
                                                !!v ||
                                                "Por favor, informe a razão social da empresa"
                                              )
                                            }
                                          ]
                                        },
                                        model: {
                                          value: _vm.company_name,
                                          callback: function($$v) {
                                            _vm.company_name = $$v
                                          },
                                          expression: "company_name"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.lead.person_type === "pj"
                                    ? _c("v-text-field", {
                                        attrs: {
                                          label:
                                            "Nome Completo do Representante Legal",
                                          type: "text",
                                          required: "",
                                          rules: [
                                            function(v) {
                                              return (
                                                !!v ||
                                                "Por favor, informe o nome do Representante Legal da Empresa"
                                              )
                                            },
                                            function(v) {
                                              return (
                                                v.length >= 10 ||
                                                "Por favor, preencha o nome completo."
                                              )
                                            }
                                          ]
                                        },
                                        model: {
                                          value: _vm.name,
                                          callback: function($$v) {
                                            _vm.name = $$v
                                          },
                                          expression: "name"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.lead.person_type === "pj"
                                    ? _c("v-text-field", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: "###.###.###-##",
                                            expression: "'###.###.###-##'"
                                          }
                                        ],
                                        attrs: {
                                          label: "CPF do Representante Legal",
                                          rules: _vm.cpf_legal_responsibleRules,
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.cpf_legal_responsible,
                                          callback: function($$v) {
                                            _vm.cpf_legal_responsible = $$v
                                          },
                                          expression: "cpf_legal_responsible"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.lead && !_vm.cupom
                            ? _c(
                                "v-row",
                                {
                                  staticClass:
                                    "text--center justify-center pt-2 mt-0"
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [_vm.lead ? _c("coupon-form") : _vm._e()],
                                    1
                                  )
                                ],
                                1
                              )
                            : (_vm.lead &&
                                _vm.lead.coupon &&
                                _vm.lead.coupon.coupon) ||
                              _vm.cupom
                            ? _c(
                                "v-row",
                                {
                                  staticClass:
                                    "text--center justify-center pt-2 pb-0 mb-0 mt-0"
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-0 mb-0 text-center",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "subtitle-1" },
                                        [
                                          _vm._v("Você ativou o cupom "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-uppercase font-weight-bold"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  (_vm.lead &&
                                                    _vm.lead.coupon &&
                                                    _vm.lead.coupon.coupon) ||
                                                    _vm.cupom.coupon
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.delCoupon(
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            dark: "",
                                                            color: "black"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-trash-can-outline"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [_c("span", [_vm._v("Remover Cupom")])]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "text--center justify-center pa-2",
                                      attrs: { cols: "10" }
                                    },
                                    [
                                      _vm.lead &&
                                      _vm.lead.coupon &&
                                      _vm.lead.coupon.custom_text
                                        ? _c("div", {
                                            staticClass:
                                              "body-2 pa-3 ma-0 black--text",
                                            staticStyle: {
                                              background: "#b2f7bf",
                                              "border-radius": "30px"
                                            },
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.lead.coupon.custom_text
                                              )
                                            }
                                          })
                                        : _vm.cupom &&
                                          _vm.cupom.type ===
                                            "percentage_discount"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "body-2 pa-1 pl-2 pr-2 ma-0 black--text text-center",
                                              staticStyle: {
                                                background: "#b2f7bf",
                                                "border-radius": "30px"
                                              }
                                            },
                                            [
                                              _vm._v(" e ganhou "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.cupom
                                                        .percentage_discount
                                                    ) + "%"
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " de economia todos os meses "
                                              )
                                            ]
                                          )
                                        : _vm.cupom &&
                                          _vm.cupom.type === "referral"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "body-2 pa-1 pl-2 pr-2 ma-0 black--text text-center",
                                              staticStyle: {
                                                background: "#b2f7bf",
                                                "border-radius": "30px"
                                              }
                                            },
                                            [
                                              _vm._v(" você foi indicado por "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold"
                                                },
                                                [_vm._v(_vm._s(_vm.cupom.user))]
                                              ),
                                              _c("br")
                                            ]
                                          )
                                        : _vm.cupom &&
                                          _vm.cupom.type === "plan_associated"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "body-2 pa-1 pl-2 pr-2 ma-0 black--text text-center",
                                              staticStyle: {
                                                background: "#b2f7bf",
                                                "border-radius": "30px"
                                              }
                                            },
                                            [
                                              _vm._v(" e ganhou "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.cupom
                                                        .percentage_discount
                                                    ) + "%"
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " de economia todos os meses "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass:
                                                "body-2 pa-1 pl-2 pr-2 ma-0 black--text text-center",
                                              staticStyle: {
                                                background: "#b2f7bf",
                                                "border-radius": "30px"
                                              }
                                            },
                                            [
                                              _vm._v(" você ganhou mais R$ "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("formatCurrency")(
                                                        _vm.cupom.discount
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                " de economia na primeira fatura "
                                              )
                                            ]
                                          )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            { staticClass: "pt-0 mt-0" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 mt-0",
                                  attrs: { cols: "12", sm: "12", md: "12" }
                                },
                                [
                                  _vm.lead.person_type === "pf"
                                    ? _c("v-checkbox", {
                                        staticClass: "pt-0 mt-0",
                                        attrs: {
                                          label:
                                            "Declaro que sou titular da conta de Luz",
                                          type: "checkbox",
                                          rules: _vm.titularRules,
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.titular,
                                          callback: function($$v) {
                                            _vm.titular = $$v
                                          },
                                          expression: "titular"
                                        }
                                      })
                                    : _c("v-checkbox", {
                                        staticClass: "pt-0 mt-0",
                                        attrs: {
                                          label:
                                            "Declaro que sou o Representante Legal da Empresa",
                                          type: "checkbox",
                                          rules:
                                            _vm.titularLegalResponsibleRules,
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.titular,
                                          callback: function($$v) {
                                            _vm.titular = $$v
                                          },
                                          expression: "titular"
                                        }
                                      }),
                                  _vm.lead.person_type === "pj"
                                    ? _c("span", { staticClass: "caption" }, [
                                        _vm._v(
                                          "Esse serviço pode ser contratato apenas pelo Representante Legal da Empresa."
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "pt-0" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0",
                                  attrs: { cols: "12", sm: "12", md: "12" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "font-weight-bold",
                                      attrs: {
                                        large: "",
                                        rounded: "",
                                        block: "",
                                        color: "primary",
                                        disabled:
                                          !_vm.valid ||
                                          _vm.loading ||
                                          _vm.error,
                                        loading: _vm.loading
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.forceSave(
                                            _vm.lead,
                                            _vm.isMember.customer_id
                                          )
                                        }
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "loader",
                                          fn: function() {
                                            return [
                                              _c("span", [
                                                _vm._v(_vm._s(_vm.loading_btn))
                                              ]),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "custom-loader"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { light: "" } },
                                                    [_vm._v("mdi-cached")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    },
                                    [_vm._v(" CONTINUAR ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "pt-0" },
                            [
                              _vm.error && _vm.error !== ""
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12", sm: "12", md: "12" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "caption red--text text-center"
                                        },
                                        [_vm._v(_vm._s(_vm.error))]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12", md: "12" } },
                                [
                                  (_vm.lead &&
                                    _vm.lead.average_consumption > 1000) ||
                                  this.error
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "mt-3",
                                          attrs: {
                                            dark: "",
                                            href:
                                              "https://api.whatsapp.com/send/?phone=5535998625580&text=Ol%C3%A1!",
                                            target: "_blank",
                                            outlined: "",
                                            rounded: "",
                                            block: "",
                                            color: "blue",
                                            disabled: _vm.loading
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-whatsapp")
                                          ]),
                                          _vm._v("Falar com um consultor")
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { ref: "focusableDiv", attrs: { tabindex: "-1" } })
            ],
            1
          ),
          _c("v-col", {
            staticClass: "d-none d-md-block bg",
            attrs: { cols: "12", xs: "12", md: "6", lg: "4" }
          })
        ],
        1
      ),
      _c(
        "v-bottom-sheet",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.dialog_isMember,
            callback: function($$v) {
              _vm.dialog_isMember = $$v
            },
            expression: "dialog_isMember"
          }
        },
        [
          _vm.isMember && _vm.isMember.code
            ? _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "" } },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { dark: "", text: "" },
                              on: {
                                click: function($event) {
                                  _vm.dialog_isMember = false
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { color: "grey" } }, [
                                _vm._v("mdi-close")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    [
                      _c("div", {
                        staticClass: "pa-6",
                        domProps: { innerHTML: _vm._s(_vm.isMember.message) }
                      }),
                      _c(
                        "v-row",
                        { staticClass: "pa-6" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", align: "center" } },
                            [
                              _vm.isMember.code === "canDecided"
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        large: "",
                                        rounded: "",
                                        color: "#27408B",
                                        loading: _vm.loading,
                                        dark: "",
                                        block: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.forceSave(
                                            _vm.lead,
                                            _vm.isMember.customer_id
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Continuar Assinatura")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", align: "center" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    small: "",
                                    color: "green",
                                    rounded: "",
                                    outlined: "",
                                    href:
                                      "https://api.whatsapp.com/send/?phone=5535998625580&text=Ol%C3%A1!",
                                    target: "_blank"
                                  }
                                },
                                [_vm._v("Falar com um consultor")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }