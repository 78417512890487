<template>
  <v-container>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="submit"
        class="ml-2 mr-2"
        name="simulador-onboarding"
    >
        <v-row class="d-flex pt-2 pb-0">
          <v-col cols="12">
            <p class="font-weight-bold text-center">Falta pouco! Informe os dados abaixo e veja o quanto você pode economizar</p>
          </v-col>
        </v-row>
        <v-row class="d-flex">
          <v-col cols="12">
            <v-btn 
              rounded dark block
              outlined
              color="black"
              @click="socialLogin('google')"
            >
              <v-icon left>mdi-google</v-icon>
              Simular com Google
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
            <span class="caption">OU</span>
          </v-col>
        </v-row>
        <v-text-field
          v-model="name"
          label="Nome Completo"
          required
          :rules="nameRules"
          :disabled="isSocialLogin && lead.hasOwnProperty('name') && lead.name !== null"
          @change="uppercaseName"
          filled
          rounded
          autofocus
          dense
        ></v-text-field>
        <v-text-field
            v-model="email"
            :rules="emailRules"
            @keyup="lowercase"
            label="E-mail"
            prepend-icon="mdi-email"
            name="email"
            type="email"
            :disabled="isSocialLogin && lead.hasOwnProperty('email') && lead.email !== null"
            required
            filled
            rounded
            dense
            class="email"
        ></v-text-field>
        <v-text-field
            type="tel"
            v-model="phone"
            :rules="phoneRules"
            :disabled="isSocialLogin && lead.hasOwnProperty('phone') && lead.phone !== null"
            prepend-icon="mdi-phone"
            label="Celular"
            placeholder="(##)#####-####"
            v-mask="['(##) #####-####']"
            required
            filled
            rounded
            dense
        ></v-text-field>
        <v-text-field
          v-model="zipcode"
          :rules="zipcodeRules"
          label="CEP"
          prepend-icon="mdi-map-marker-minus-outline"
          name="cep"
          type="tel"
          v-mask="['#####-###']"
          @change="buscaCep($event)"
          required
          filled
          rounded
          dense
        ></v-text-field>
        <v-text-field
          v-model="cupom"
          label="Cupom de desconto (Opcional)"
          prepend-icon="mdi-sale"
          name="cupom"
          required
          filled
          rounded
          dense
          ref="cupom"
          @keyup="uppercase"
          @keypress="isValidCoupon = true"
          @keydown="isValidCoupon = true"
        ></v-text-field>
        <v-autocomplete
          v-model.lazy="utility"
          :items="filteredUtilityCompanies"
          label="Concessionária"
          item-text="text"
          item-value="value"
          required
          filled
          rounded
          dense
        ></v-autocomplete> 
      <div class="pt-0 pb-3 caption text-center red--text" v-if="isValidCoupon === false">
        <!-- Ops! Esse cupom é inválido!. -->
        {{couponMessage}}
      </div>  
      <div class="body-2 pb-6 pt-0">
        <v-checkbox
          v-model="optin"
          label="Quero receber mais informações para economizar a minha conta"
        ></v-checkbox>
        
        <!-- Ao inserir os dados acima eu concordo em ser contatado sobre os produtos da Juntos Energia, incluindo por meio de chamadas ou textos. -->
      </div>
      <div class="text-center red--text" v-if="error_message">
        {{error_message}}
      </div>
      <v-btn
        color="primary"
        class="mr-4"
        type="submit"
        @click="submit"
        :disabled="!valid || loading || !isValidCoupon"
        :loading="loading"
        rounded
        block
        large
      >
        Continuar
      </v-btn>
    </v-form>
  </v-container>
</template>
<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { formatPhone, unformatPhone } from '../../utils/phone'
  import { getCEP } from '../../services/cep'
  import { convertRDStation } from '../../services/rdstation'
  import { loginAnonymous } from '../../services/users'
  export default {
    async mounted() {
      if(!localStorage.getItem('token')) {
        await loginAnonymous()
          .then((res) => {
            let token = res.user.za
            if(token) localStorage.setItem('token', token)
          })
          .catch(err => console.error(err))
      }
      await this.getUtilityCompanies();
      if(this.lead) {
        this.setLead(this.lead)
      } else {
        if(localStorage.getItem('lead')) {
          this.setLead(JSON.parse(localStorage.getItem('lead')))
        } else {
          if(this.$route.query.email) this.email = this.$route.query.email
        }
      }

    },
    data: () => ({
      name: null,
    nameRules: [
      v => !!v || 'Ops! Por favor, informe o nome.',
      v => /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u.test(v)
        && v?.length >= 3
        && v.includes(' ') || 'Informe seu nome completo.'
    ],
      email: null,
      emailRules: [
        v => !!v || 'Ops! Por favor, informe o seu email.',
        v => /.+@.+\..+/.test(v) || 'Ops! Esse valide se você digitou o e-mail corretamente.',
      ],
      phone: null,
      phoneRules: [
        v => !!v || "Por favor, informe o seu celular",
        v => v && v?.length > 10 || "Ops. Parece que esse não é um número de telefone"
      ],
      zipcode: null,
      zipcodeRules: [
        v => !!v || "Por favor, informe o seu CEP",
        v => v && v.length >= 8 || "Ops. Parece que esse não é um cep válido"
      ],
      average_consumption: null,
      averageRules: [
        v => !!v || "Por favor, informe quanto você gasta em sua conta de luz",
        v => v > 149 || "Ops! Você precisa gastar pelo menos 150 reais para simular"
      ],
      utility: 'cemig',
      person_type: null,
      whatsapp_optin: true,
      sms_optin: true,
      email_optin: true,
      address: null,
      valid: false,
      loading: false,
      error_message: null,
      cupom: null,
      isValidCoupon: true,
      CupomMessage: null,
      optin: true,
    }),
    computed: {
      ...mapState(['lead', 'coupon', 'utilityCompanies']),
      ...mapGetters(['isSocialLogin', 'filteredUtilityCompanies'])
    },

    methods: {
      ...mapActions(['saveLead', 'facebookSignUp', 'googleSignUp', 'getCoupons', 'getPlan', 'getUtilityCompanies']),
      async submit () {
        this.loading = true
        if(this.valid && this.$refs.form.validate() && this.isValidCoupon) {
          if(this.cupom && this.cupom.trim() !== '') {
            let valida = await this.validateCupom(this.cupom.trim())
            if(!valida) {
              this.loading = false
              return
            }
          }
          if(!this.address) {
            let address = {
              address: '',
              address_number: '',
              address_complement: '',
              district: '',
              postal_code: '',
              state: {
                initials: 'MG',
                name: 'Minas Gerais'
              },
              city: '',
              country: {
                initials: 'br',
                name: 'Brasil'
              },
            }
            this.address = address 
            if(this.zipcode) {
              let _address = await this.buscaCep(this.zipcode)
              if(_address && _address.address) this.address = _address
            }
          }
          // console.log('start lead')
          let lead = {
            ...this.lead,
              person_type: this.person_type,
              name: this.name,
              email: this.email,
              phone: formatPhone(this.phone,'+55'),
              // average_consumption: this.average_consumption,
              whatsapp_optin: this.optin,
              sms_optin: this.optin,
              email_optin: this.optin,
              zipcode: this.zipcode?this.zipcode.replace(/\D/g, ''):null,
              channel: 'web',
          platform: 'web',
          utility: this.utility,
          step: this.$route.name,
        }
        if (this.address) {
          lead.address = this.address
        }
        if (this.coupon) {
          lead.coupon = this.coupon
          if(lead?.coupon?.type === "referral") {
            lead.promo_code = lead.coupon.user
          }
        }
        this.saveLead(lead)
          .then(() => {
            this.$gtm.trackEvent({
              event: 'step1_cadastro',
              category: "onboard",
              action: "click",
              label: "Cadastro na primeira pagina do onboard",
              value: lead.average_consumption,
              noninteraction: false, // Optional
              step: this.$route.name,
              email: lead.email,
              phone: lead.phone,
              utility: lead.utility,
              person_type: lead.person_type
            });
              convertRDStation('step1_cadastro', lead)
                .catch(err => console.error('Erro ao gravar a conversão no RD Station. Erro: ', err.error))
                .then(() => console.log('Conversão step1_cadastro gravada com sucesso no RD Station'))

              this.loading = false
              if (this.utilityCompanies.find(item => item.value == lead.utility)) {
                this.$router.push({path: '/simulador', query: this.$route.query})
              } else {
                this.$router.push({path: '/lista-espera', query: this.$route.query})
              }
            })
            .catch(err => {
              console.error(err)
              this.loading = false
            })
        } else {
          this.loading = false
        }
      },
      async socialLogin(type) {
        let user;
        if(type === 'facebook') {
          user = await this.facebookSignUp()
          // console.log('fb user: ', user)
          if(user && user.code === 'auth/account-exists-with-different-credential') this.error_message = 'Membro já utiliza esse email para outra conta.'
        }
        else if(type === 'google') { 
          user = await this.googleSignUp()
        }
        if(user) {
          this.name = user.name || ''
          this.email = user.email || ''
          this.phone = user.phone || null
        }
      },
      setLead(lead) {
        if(lead.name) this.name = lead.name
        if(lead.email) this.email = lead.email
        if(lead.phone) this.phone = unformatPhone(lead.phone, '55')
        if(lead.average_consumption) this.average_consumption = lead.average_consumption
        if(lead.zipcode) this.zipcode = lead.zipcode
        if(lead.address) this.address = lead.address
        if(lead.person_type) this.person_type = lead.person_type
        if(lead.utility) this.utility = lead.utility
      },
      clear () {
        this.name = ''
        this.phoneNumber = ''
        this.email = ''
        this.zipcode = ''
        this.select = null
        this.address = null
        this.checkbox = null
        this.$refs.observer.reset()
      },
      buscaCep(cep) {
        if(cep && cep !== '') {
          // this.loading_cep = true
          getCEP(cep)
            .then(async result => {
              let address = {
                address: result.data.logradouro || '',
                address_number: result.data.numero || '',
                address_complement: result.data.complement || '',
                district: result.data.bairro || '',
                postal_code: cep  || '',
                state: {
                  initials: result.data.uf || 'MG',
                  name: result.data.uf || 'Minas Gerais'
                },
                city: result.data.localidade || '',
                country: {
                  initials: 'br',
                  name: 'Brasil'
                },
              }
              this.address = address
              return address
            })
            .catch(error => {
              return error
            })
        } else return null
      },
      validateCupom(cupom) {
        // this.loading = true
        if(!cupom || cupom.trim() === '') {
          this.loading = false
          this.isValidCoupon = true
          return true
        }
        return this.getCoupons(cupom)
          .then(res => {
            // console.log('res: ', res)
            this.isValidCoupon = res.status
            this.couponMessage = res.message
            // this.loading = false
            return res.status
          })
          .catch(err => {
            console.error(err)
            this.isValidCoupon = false
            this.couponMessage = err.message
            // this.loading = false
            this.valid = false
            return false
          })
      },
      uppercaseName() {
        if (this.name) {
          this.name = this.name.replace(/\b\w/g, l => l.toUpperCase());
          this.name = this.name[this.name?.length - 1] === ' ' ? this.name.slice(0, -1) : this.name  
      }
      },
      uppercase() {
        this.cupom = this.cupom ? this.cupom.toUpperCase() : ''
      },
      lowercase() {
        this.email = this.email ? this.email.toLowerCase() : ''
      }
    },
  }
</script>
<style>
  .email input {
    text-transform: lowercase;
  }
</style>