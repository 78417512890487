<template>
    <v-container fluid class="ma-0">
        <v-row>
            <v-col cols="12" xs="12" md="6" lg="4" class="d-none d-md-block bg"/>
            <v-col cols="12" md="6" lg="4" class="pa-0 form">
                <steps :step="step" :total="5" :itens="steps_item"/>
                <card-form 
                    class="pa-6"
                />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4" class="d-none d-md-block bg"/>
        </v-row>
    </v-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import CardForm from '../components/Simulador/CardForm.vue'
import Steps from '../components/Steps'
import { convertRDStation } from '../services/rdstation'
// import images from '../utils/images'

export default {
    components: {
        CardForm,
        Steps
    },
    data: () => {
        return {
            steps_item: ['Formas de Pagamento', 'Detalhes da Migração'],
            step: 3,
            image: null
        }
    },
    async mounted() {
        if(!this.user) {
            await this.getUserInitial(this.lead?.email);
        }

        this.$gtm.trackEvent({
            event: 'step4_forma_pgto',
            category: "onboard",
            action: "pageview",
            label: "Acessou pagina de formas de pagamento",
            noninteraction: false, // Optional
            step: this.$route.name,
            email: this.user?.email || this.lead?.email,
            phone: this.user?.phone || this.lead?.phone,
            person_type: this.user?.person_type || this.lead?.person_type,
            value: this.lead?.average_consumption,
        });
        convertRDStation('step4_forma_pgto', this.lead)
            .catch(err => console.error('Erro ao gravar a conversáo no RD Station. Erro: ', err))
            .then(() => console.log('Conversão step4_forma_pgto gravada com sucesso no RD Station'))
        

    },
    computed: {
        ...mapState(['lead', 'user'])
    },
    methods: {
        ...mapActions(["getUserInitial"]),
    }
}
</script>