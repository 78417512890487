import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
import colors from 'vuetify/lib/util/colors'

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#7c3dff',
            secondary: '#ed0c5e',
            accent: '#82B1FF',
            error: '#ed0d5e',
            info: '#8C8C8C',
            success: '#4CAF50',
            warning: '#FFC107',
            yellow: '#efef0c',
            blue: '#00a4ff',
          },
          dark: {
            primary: colors.blue.lighten3,
            yellow: '#efef0c',
            blue: '#00a4ff'
          },
        },
    },
    icons: {
        iconfont: 'mdiSvg' || 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
});

