import { firestore } from "../firebase/firestore"

export const getLead = (email) => {
    return firestore.collection('leads').doc(email).get()
};

export const storeLead = (email, data) => {
    const ref = firestore.collection('leads').doc(email)
    data.updated_at = new Date()
    return ref
        .get()
        .then(lead => {
            if(lead.exists) {
                return ref.update(data)
                    .then(() => {
                        return ref.get()
                    })
                    .catch(error => {
                        console.error('Erro ao atualizar usuário')
                        return error
                    })
            } else {
                data.created_at = new Date()
                return ref.set(data)
                    .then(() => {
                        return ref.get()
                    })
                    .catch(error => {
                        console.error('Erro ao atualizar usuário')
                        return error
                    })
            }
        })
        .catch(error => {
            console.error('Erro ao atualizar usuário. ', error.message)
            return error
        })
}

export const updateLead = (email, data) => {
    // console.log('Inicia atualizaçào do lead ('+email+'): ', data)
    let ref = firestore.collection('leads').doc(email)
    ref.update(data)
        .then(() => {
            return ref.get()
        })
        .catch(error => {
            console.error('Erro ao atualizar usuário')
            return error
        })
}