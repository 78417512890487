<template>
    <v-container fluid class="ma-0">
        <v-row>
            <v-col cols="12" xs="12" md="6" lg="4" class="d-none d-md-block bg"/>
            <v-col cols="12" md="6" lg="4" class="pa-0 form">
                <steps :step="4" :total="5" :itens="['Detalhes da Migração', 'Fazer Portabiliade']"/>
                <v-container>
                    <v-row class="pa-2 pt-2 pb-0">
                        <v-col cols="12" class="pt-6">
                            <h2 class="text-capitalize">{{lead && lead.name?lead.name:'Maria da Silva'}}</h2>
                            <p class="pt-0 pb-0 mb-0">Cel. {{lead && lead.phone?lead.phone:'(35) 99999-9999'}}</p>
                            <p class="pt-0 mt-0 mb-0">E-mail: {{lead && lead.email?lead.email:'maria@teste.com.br'}}</p>
                            <p>CPF: {{lead && lead.cpf?lead.cpf:'123.456.789-12'}}</p>
                        </v-col>
                        <v-col cols="12" class="pt-0">
                            <v-divider/>
                        </v-col>
                    </v-row>
                    <v-row class="pa-2 pt-0 pb-2">
                        <v-col cols="12">
                            <h3 class="pb-4">Confira o(s) imóvel(s) apto(s) a portabilidade de energia limpa agora mesmo!</h3>
                            <v-skeleton-loader
                                v-bind="attrs"
                                type="list-item-avatar-three-line, image, article"
                                v-if="loading_start"
                                width="400"
                            ></v-skeleton-loader>
                            <v-row v-for="(qualification, idx) in qualifications" :key="idx" class="px-2">
                                <qualifing-card :qualification="qualification" v-if="qualification.status === 'pending'"/>
                                <qualifing-card :qualification="qualification" v-if="qualification.status === 'bill_not_found' || qualification.status === 'error'"/>
                                <disqualifield-card :qualification="qualification" v-if="qualification.status === 'disqualified'"/>
                                <qualifield-card :qualification="qualification" v-if="qualification.status === 'qualified'"/>
                            </v-row>
                            <v-row class="pa-3" v-if="qualifications && qualifications.length <=0 && user && !user.hasOwnProperty('installations_found')">
                                <p>Não conseguimos fazer a validação automática do seu imóvel.</p>
                                <p>Mas não tem problema! Continue a sua portabilidade que nosso time fará o processo manualmente.</p>
                                <p>Assim que for finalizada você receberá um e-mail de confirmação.</p>
                            </v-row>
                            <not-qualifing-card v-if="not_qualification === true" :lead="lead"/>
                        </v-col>
                        
                    </v-row>
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        @submit.prevent="submit"
                        class="ml-2 mr-2 pt-6"
                    >
                        
                        <v-row>
                            <v-col cols="12" v-if="error_message" class="text-center red--text">
                                <p class="body-1 text-center">{{error_message}}</p>
                            </v-col>
                            <v-col cols="12">
                                <v-btn
                                    color="secondary"
                                    block
                                    rounded
                                    large
                                    :loading="loading"
                                    :disabled="!valid"
                                    @click.prevent="submit"
                                    type="submit"
                                    class="text-none"
                                    >Continuar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
                <qualification-detail :type="qualification_type" :uc="uc"/>
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4" class="d-none d-md-block bg"/>
        </v-row>
    </v-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import QualificationDetail from '../components/Qualification/QualificationDetail.vue'
import DisqualifieldCard from '../components/Qualification/DisqualifieldCard.vue'
import QualifingCard from '../components/Qualification/QualifingCard.vue'
import QualifieldCard from '../components/Qualification/QualifieldCard.vue'
import NotQualifingCard from '../components/Qualification/NotQualifingCard.vue'

import Steps from '../components/Steps.vue'
import { eventBus } from '../main'
import { convertRDStation } from '../services/rdstation'

export default {
    components: {
        Steps,
        QualificationDetail,
        DisqualifieldCard,
        QualifingCard,
        QualifieldCard,
        NotQualifingCard
    },
    async mounted() {
        if(!this.user) {
            this.$router.push({path: '/', query: this.$route.query})
            return null
        }
        else if(this.user && (!this.qualifications || this.qualifications.length <= 0)) {
            let cpf = this.user.cpf
            
            await this.getQualification(cpf)
                .then(() => {
                    this.loading_start = false
                })
                .catch(err => {
                    console.error(err)
                    this.loading_start = false
                })

            this.$gtm.trackEvent({
                event: 'step5_validacao',
                category: "onboard",
                action: "pageview",
                label: "Acessou pagina de validação de qualificação",
                noninteraction: false, // Optional
                payment_method: this.user.payment_method || this.lead.payment_method,
                step: this.$route.name,
                email: this.user?.email || this.lead?.email,
                phone: this.user?.phone || this.lead?.phone,
                person_type: this.user?.person_type || this.lead?.person_type,
                value: this.user?.average_consumption || this.lead?.average_consumption,
            });
            convertRDStation('step5_validacao', this.lead)
                    .catch(err => console.error('Erro ao gravar a conversáo no RD Station. Erro: ', err))
                    .then(() => console.log('Conversão step5_validacao gravada com sucesso no RD Station'))
        } else {
            this.loading_start = false
        }
        
        
        
    },
    data: () => ({
        loading_start: true,
        loading: false,
        password: null,
        passwordRules: [
            v => !!v || "Informe a senha",
            v => v && v === true || "Ops. aceite os termos para seguir"
        ],
        confirm_password: null,
        confirm_passwordRules: [
            v => !!v || "Confirme sua sennha",
            v => v && v ===this.password || "Ops. senhas não conferem"
        ],
        termo: false,
        termoRules: [
            v => !!v || "Obrigatório o aceite do contrato para adesão",
            v => v && v === true || "Ops. aceite os termos para seguir"
        ],
        valid: false,
        dialog_uc_detail: false,
        qualification_type: '',
        not_qualification: false,
        uc: '',
        image: null,
        attrs: {
            class: 'mb-6',
            boilerplate: true,
            elevation: 2,
        },
        isDisqualification: false,
        error_message: null,
        // allDisqualified: false
    }),
    computed: {
        ...mapState(['lead', 'user', 'qualifications']),
    },
    methods: {
        ...mapActions(['getQualification', 'getLocalUser', 'getLocalLead', 'saveLead', 'saveUser']),
        openQualificationDetail(uc, type) {
            this.uc = uc,
            this.qualification_type = type,
            eventBus.$emit('open-dialog_qualification_details')
        },
        async submit() {
            this.error_message = false
            let res = await this.getDesqualification()
            if(res) this.error_message = 'Não há nenhum imóvel qualificavel'
            else this.$router.push({path: '/assinatura', query: this.$route.query})
        },
        getDesqualification() {
            let disqualification = this.qualifications.reduce((sum, cur) => {
                if(cur.status === 'disqualified') return sum + 1
                else return sum
            }, 0)
            let reason_disqualify = this.qualifications.map((cur) => {
                if(cur.status === 'disqualified') {
                    return {
                        consumer_unit: cur.consumer_unit,
                        reason_desc: cur.reason_disqualify_desc,
                        reason: cur.reason_disqualify
                    }
                }
                else return
            })
            if(disqualification === this.qualifications.length && disqualification > 0) {
                this.isDisqualification = true
                // Marca lead e user como desqualificado
                let new_lead = {
                    ...this.user,
                    reason_disqualify: reason_disqualify,
                    status: 'DESQUALIFICAR',
                    step: this.$route.name,
                }
                this.saveLead(new_lead)

                let new_user = {
                    ...this.user,
                    reason_disqualify: reason_disqualify,
                    status: 'DESQUALIFICAR'
                }
                this.saveUser(new_user)
                // Adicionar evento de desqualificação
                return true
            }
            else {
                return false
            }
        }
    },
    watch: {
        qualifications() {
            if(!this.qualifications || this.qualifications.length < 0) {
                this.not_qualification = true
            } else {
                let disqualified = this.qualifications.reduce((sum, cur) => {
                    if(cur.status === 'disqualified') return sum + 1
                    else return sum
                }, 0)
                if(disqualified === this.qualifications.length && this.qualifications.length > 0) {
                    this.isDisqualification = true
                    // Adicionar evento de desqualificação
                    this.getDesqualification()
                    return
                }           
            }
            
        }
    }
}
</script>