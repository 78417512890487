var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto rounded-xl elevation-1 pa-2" },
    [
      _c("v-card-title", { staticClass: "ml-2 mr-2" }, [
        _c("h3", [_vm._v("Faça uma simulação")])
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            {
              ref: "form",
              staticClass: "ml-2 mr-2",
              attrs: { "lazy-validation": "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submit($event)
                }
              },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v("Quanto você gasta de energia por mês?")
              ]),
              _c("v-text-field", {
                attrs: {
                  required: "",
                  prefix: "R$",
                  rules: _vm.averageRules,
                  type: "number"
                },
                model: {
                  value: _vm.average_consumption,
                  callback: function($$v) {
                    _vm.average_consumption = _vm._n($$v)
                  },
                  expression: "average_consumption"
                }
              }),
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v("Qual é a sua concessionária?")
              ]),
              _c(
                "v-row",
                { staticClass: "pt-2 pb-4" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6", sm: "6", md: "6", align: "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            rounded: "",
                            color: "primary",
                            dark: "",
                            outlined: _vm.utility === "outra",
                            block: ""
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.setUtility("cemig")
                            }
                          }
                        },
                        [
                          _vm.utility !== "outra"
                            ? _c(
                                "v-icon",
                                { attrs: { color: "white", left: "" } },
                                [_vm._v("mdi-check")]
                              )
                            : _vm._e(),
                          _vm._v(" Cemig")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6", sm: "6", md: "6", align: "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            rounded: "",
                            color: "primary",
                            dark: "",
                            outlined: _vm.utility !== "outra",
                            block: ""
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.setUtility("outra")
                            }
                          }
                        },
                        [
                          _vm.utility === "outra"
                            ? _c(
                                "v-icon",
                                { attrs: { color: "white", left: "" } },
                                [_vm._v("mdi-check")]
                              )
                            : _vm._e(),
                          _vm._v(" Outra ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v("Para minha")
              ]),
              _c(
                "v-row",
                { staticClass: "pt-2 pb-4" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6", sm: "6", md: "6", align: "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            rounded: "",
                            color: "primary",
                            dark: "",
                            outlined: _vm.person_type === "pj",
                            block: ""
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.setPerson("pf")
                            }
                          }
                        },
                        [
                          _vm.person_type !== "pj"
                            ? _c(
                                "v-icon",
                                { attrs: { color: "white", left: "" } },
                                [_vm._v("mdi-check")]
                              )
                            : _vm._e(),
                          _vm._v(" Casa "),
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color:
                                  _vm.person_type === "pf"
                                    ? "white"
                                    : "primary",
                                right: ""
                              }
                            },
                            [_vm._v("mdi-home-outline")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6", sm: "6", md: "6", align: "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            rounded: "",
                            color: "primary",
                            dark: "",
                            outlined: _vm.person_type !== "pj",
                            block: ""
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.setPerson("pj")
                            }
                          }
                        },
                        [
                          _vm.person_type === "pj"
                            ? _c(
                                "v-icon",
                                { attrs: { color: "white", left: "" } },
                                [_vm._v("mdi-check")]
                              )
                            : _vm._e(),
                          _vm._v(" Empresa "),
                          _vm.person_type !== "pj"
                            ? _c(
                                "v-icon",
                                { attrs: { color: "primary", right: "" } },
                                [_vm._v("mdi-office-building-marker")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mt-3",
                  attrs: {
                    color: "secondary",
                    block: "",
                    rounded: "",
                    disabled: !_vm.valid
                  },
                  on: { click: _vm.submit }
                },
                [_vm._v(" Simular ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }