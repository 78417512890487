var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "text-center justify-center pt-0 mt-0" },
        [
          _vm.plans
            ? _c(
                "div",
                _vm._l(_vm.plansOrder, function(item) {
                  return _c(
                    "v-card",
                    {
                      key: item.id,
                      staticClass: "pa-4 mt-3 rounded-xl ",
                      style:
                        (_vm.plan && _vm.plan.id === item.id) ||
                        (!_vm.plan && item.recommended)
                          ? "border: 1px solid #000080"
                          : "border: 1px solid #c0c0c0",
                      attrs: {
                        elevation: "2",
                        light: !(
                          (_vm.plan && _vm.plan.id === item.id) ||
                          (!_vm.plan && item.recommended)
                        ),
                        color:
                          (_vm.plan && _vm.plan.id === item.id) ||
                          (!_vm.plan && item.recommended)
                            ? "blue"
                            : "white",
                        dark:
                          (_vm.plan && _vm.plan.id === item.id) ||
                          (!_vm.plan && item.recommended)
                      },
                      on: {
                        click: function($event) {
                          return _vm.toggle_plan(item)
                        }
                      }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "pb-0 pt-0" },
                        [
                          _c(
                            "v-list-item",
                            { attrs: { "three-line": "" } },
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "pa-4 mb-3 pr-0 pb-0 pt-0" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "body-1 pl-7 pt-0" },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                ]
                              ),
                              _c(
                                "v-list-item-avatar",
                                {
                                  staticClass: "pa-4 mb-3 pr-0 pb-0 pt-0",
                                  attrs: { tile: "" }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color:
                                          (_vm.plan &&
                                            _vm.plan.id === item.id) ||
                                          (!_vm.plan && item.recommended)
                                            ? "white"
                                            : "grey"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            (_vm.plan &&
                                              _vm.plan.id === item.id) ||
                                              (!_vm.plan && item.recommended)
                                              ? "mdi-check-circle"
                                              : "mdi-radiobox-blank"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "pt-0 mt-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-2 mt-0",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("h2", { staticClass: "pt-0" }, [
                                _vm._v("Economize até")
                              ]),
                              _c("h1", [
                                _vm._v(
                                  "R$ " +
                                    _vm._s(
                                      _vm._f("formatCurrency")(
                                        _vm.calcEconomy(item.discount)
                                      )
                                    )
                                )
                              ]),
                              _c("div", { staticClass: "subtitle" }, [
                                _vm._v(_vm._s(_vm.economy_label))
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "text--center justify-center pt-0 mt-0"
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text--center justify-center",
                              attrs: { cols: "6" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "body-2 pt-2 pb-2 black--text",
                                  staticStyle: {
                                    background: "#efefef",
                                    "border-radius": "20px"
                                  }
                                },
                                [
                                  _vm._v(" R$ "),
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatCurrency")(
                                            _vm.calcEconomy(item.discount) / 12
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" por mês ")
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "text--center justify-center caption pt-1 mt-0"
                        },
                        [_c("plan-conditions")],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0 mt-0", attrs: { cols: "12" } },
                        [
                          _c("h2", { staticClass: "pt-0" }, [
                            _vm._v("Economize até")
                          ]),
                          _c("h1", [
                            _vm._v(
                              "R$ " +
                                _vm._s(_vm._f("formatCurrency")(this.economy))
                            )
                          ]),
                          _c("div", { staticClass: "subtitle" }, [
                            _vm._v(_vm._s(_vm.economy_label))
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "text--center justify-center pt-0 mt-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text--center justify-center",
                          attrs: { cols: "6" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "body-2 pt-2 pb-2 black--text",
                              staticStyle: {
                                background: "#efefef",
                                "border-radius": "20px"
                              }
                            },
                            [
                              _vm._v(" R$ "),
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatCurrency")(this.economy / 12)
                                  )
                                )
                              ]),
                              _vm._v(" por mês ")
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "text--center justify-center caption pt-1 mt-0"
                    },
                    [_c("plan-conditions")],
                    1
                  )
                ],
                1
              ),
          _c(
            "v-row",
            { staticClass: "pt-2" },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("span", { staticClass: "subtitle-2" }, [
                  _vm._v("Você também contribui para o planeta com")
                ])
              ])
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "4", align: "center" } },
                [
                  _c("v-img", {
                    attrs: {
                      src: require("@/assets/benefits/carbon-dioxide.svg"),
                      width: "60%"
                    }
                  }),
                  _c("h2", [
                    _vm._v(
                      _vm._s(
                        _vm.enviroments.co2ton
                          ? _vm.enviroments.co2ton.toFixed(0)
                          : 0
                      )
                    )
                  ]),
                  _c("div", { staticClass: "subtitle" }, [
                    _vm._v("tons de redução de co2")
                  ])
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4", align: "center" } },
                [
                  _c("v-img", {
                    attrs: {
                      src: require("@/assets/benefits/trees.svg"),
                      width: "60%"
                    }
                  }),
                  _c("h2", [_vm._v(_vm._s(_vm.enviroments.trees.toFixed(0)))]),
                  _c("div", { staticClass: "subtitle" }, [
                    _vm._v("árvores plantadas")
                  ])
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4", align: "center" } },
                [
                  _c("v-img", {
                    attrs: {
                      src: require("@/assets/benefits/oil-barrel.svg"),
                      width: "60%"
                    }
                  }),
                  _c("h2", [
                    _vm._v(_vm._s(_vm.enviroments.oilBarrel.toFixed(0)))
                  ]),
                  _c("div", { staticClass: "subtitle" }, [
                    _vm._v("redução barris de petróleo")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm.lead && _vm.lead.origin_type === "direct" && !_vm.cupom
            ? _c(
                "v-row",
                { staticClass: "text--center justify-center pt-2 mt-0" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.lead && _vm.lead.origin_type === "direct"
                        ? _c("coupon-form")
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : (_vm.lead && _vm.lead.coupon && _vm.lead.coupon.coupon) ||
              _vm.cupom
            ? _c(
                "v-row",
                { staticClass: "text--center justify-center pt-2 mt-0" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("span", { staticClass: "subtitle-1" }, [
                        _vm._v("Você ativou o cupom "),
                        _c(
                          "span",
                          { staticClass: "text-uppercase font-weight-bold" },
                          [
                            _vm._v(
                              _vm._s(
                                (_vm.lead &&
                                  _vm.lead.coupon &&
                                  _vm.lead.coupon.coupon) ||
                                  _vm.cupom.coupon
                              )
                            )
                          ]
                        )
                      ]),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: { icon: "" },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.delCoupon($event)
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { dark: "", color: "black" } },
                                        [_vm._v("mdi-trash-can-outline")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [_c("span", [_vm._v("Remover Cupom")])]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text--center justify-center pa-0",
                      attrs: { cols: "8" }
                    },
                    [
                      _vm.lead && _vm.lead.coupon && _vm.lead.coupon.custom_text
                        ? _c("div", {
                            staticClass: "body-2 pa-3 ma-0 black--text",
                            staticStyle: {
                              background: "#b2f7bf",
                              "border-radius": "30px"
                            },
                            domProps: {
                              innerHTML: _vm._s(_vm.lead.coupon.custom_text)
                            }
                          })
                        : _vm.cupom && _vm.cupom.coupon
                        ? _c("div", {
                            staticClass: "body-2 pa-3 ma-0 black--text",
                            staticStyle: {
                              background: "#b2f7bf",
                              "border-radius": "30px"
                            },
                            domProps: {
                              innerHTML: _vm._s(_vm.cupom.custom_text)
                            }
                          })
                        : _c(
                            "div",
                            {
                              staticClass: "body-2 pa-3 ma-0 black--text",
                              staticStyle: {
                                background: "#b2f7bf",
                                "border-radius": "30px"
                              }
                            },
                            [
                              _vm._v(" economiza mais R$ "),
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      (_vm.lead &&
                                        _vm.lead.coupon &&
                                        _vm.lead.coupon.discount) ||
                                        _vm.cupom.discount
                                    )
                                  )
                                )
                              ]),
                              _c("br"),
                              _vm._v(" na primeira fatura ")
                            ]
                          )
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("how-works")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }