var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _c(
        "v-card",
        {
          staticClass: "d-flex justify-center align-center mb-6",
          attrs: {
            color: _vm.$vuetify.theme.dark
              ? "white darken-3"
              : "white lighten-4",
            flat: "",
            tile: "",
            height: "100vh"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "pa-2",
              attrs: {
                color: _vm.$vuetify.theme.dark
                  ? "whbt6 darken-3"
                  : "white lighten-4",
                outlined: "",
                flat: "",
                tile: ""
              }
            },
            [
              _c(
                "v-row",
                { staticClass: "justify-center pb-6" },
                [
                  _c("v-img", {
                    attrs: {
                      src: require("@/assets/novo/Logo-novo.svg"),
                      "max-height": "100",
                      "max-width": "200"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "justify-center" },
                [
                  _c("v-img", {
                    attrs: {
                      src: require("@/assets/errors/surprised.png"),
                      "max-height": "300",
                      "max-width": "300"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "justify-center text-center ma-3 pt-3" },
                [
                  _c("h2", [
                    _vm._v(
                      "Identificamos que o membro " +
                        _vm._s(
                          _vm.user && _vm.user.name
                            ? _vm.user.name.split(" ")[0]
                            : ""
                        ) +
                        " " +
                        _vm._s(
                          _vm.user && _vm.user.email
                            ? " | " + _vm.user.email
                            : ""
                        ) +
                        " já está cadastrado!"
                    )
                  ])
                ]
              ),
              _c(
                "v-row",
                { staticClass: "justify-center" },
                [
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "green darken-2",
                            text: "",
                            target: "_blank"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.clear($event)
                            }
                          }
                        },
                        [_vm._v(" cadastrar novo ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "justify-center" },
                [
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "blue darken-2",
                            text: "",
                            href: "https://bit.ly/whatsapp_Enercred_vendas",
                            target: "_blank"
                          }
                        },
                        [_vm._v(" quero ajuda ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }