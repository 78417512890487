<template>
    <v-card flat class="overflow-hidden" color="#efefef" :shaped="shaped?true:false">
        <v-row class="pa-3 mt-0" align="center">
            <v-col cols="2 pl-6">
                <v-btn color="#000000" outlined rounded><span class="font-weight-bold">{{step}}</span>/{{total}}</v-btn>
            </v-col>
            <v-col cols="10">
                <div class="text-end pr-4 body-2"><span class="font-weight-bold">Você está em: </span>{{itens[0]}}</div>
                <div v-if="itens.length > 1" class="text-end pr-4  body-2"><span class="font-weight-bold">Próximo: </span>{{itens[1]}}</div>
            </v-col>
        </v-row>
    </v-card>
    <!-- <div class="text-end pa-2">
        <v-btn color="grey" small disabled rounded>{{step}}/{{total}}</v-btn>
    </div> -->
    <!-- <v-row class="pa-2 pb-0">
        <v-col v-for="(tot,i) in total" :key="i">
            <div class="d-flex justify-center">
                <v-btn
                    x-small
                    icon
                    dark
                    outlined
                    :color="tot === step?'blue': '#c0c0c0'"
                    v-if="tot >= step"
                >
                    {{tot}}
                </v-btn>
                <v-btn
                    x-small
                    icon
                    dark
                    outlined
                    :color="tot === step?'blue': '#c0c0c0'"
                    v-else
                >
                    <v-icon>mdi-check</v-icon>
                </v-btn>
            </div>
            <div class="text-center justify-center caption pb-0" v-if="tot >= step && itens && itens.length >= 0">
                <div :class="tot === step?'blue--text font-weight-bold': 'grey--text'">{{itens[tot-step]}}</div>
            </div>

        </v-col>
    </v-row> -->
</template>
<script>
export default {
    props: ['step', 'total', 'itens', 'shaped']
}
</script>