<template>
  <v-container>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="submit"
      class="ml-2 mr-2"
    >
      <v-row class="pb-0 m-0">
        <v-col class="text-center pb-0">
          <p class="mb-0 text-center">
            Preencha o formulário abaixo para adicionar um novo cartão
          </p>
        </v-col>
      </v-row>
      <v-row class="pt-2 pb-0 m-0">
        <v-img
          :src="
            require('@/assets/cards/images/' + currentCardBackground + '.jpeg')
          "
          style="border-radius: 30px"
        >
          <v-card color="transparent elevation-0" class="white--text pa-5 ma-2">
            <v-row class="pa-2 pt-2 pb-2" justify="space-between">
              <v-col cols="8">
                <v-img
                  :src="require('@/assets/cards/images/chip.png')"
                  width="48px"
                />
              </v-col>
              <v-col cols="4" class="pa-2">
                <v-img
                  :src="require('@/assets/cards/images/' + card_type + '.png')"
                  max-height="70px"
                  style="max-height: 70px !important; width: auto !important"
                />
              </v-col>
              <v-col cols="12" class="">
                <h3>
                  {{ maskCardNumber(cardNumber) || "0000 0000 0000 0000" }}
                </h3>
              </v-col>
              <v-col cols="9" class="">
                <h4>{{ cardName || "Nikola Tesla" }}</h4>
              </v-col>
              <v-col cols="3">{{ expire || "09/25" }}</v-col>
            </v-row>
          </v-card>
        </v-img>
        <v-row>
          <v-col class="pt-6 pb-0">
            <v-text-field
              v-model="cardName"
              label="Nome como está no cartão"
              required
              :rules="nameRules"
              filled
              rounded
              autofocus
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pt-0 pb-0">
            <v-text-field
              type="tel"
              v-model="cardNumber"
              :rules="numberRules"
              label="Numero do Cartão"
              placeholder="####-####-####-####"
              :maxlength="cardNumberMaxLength"
              return-masked-value
              v-mask="cardMask"
              required
              filled
              rounded
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pt-0 pb-0">
            <v-text-field
              v-model="expire"
              :rules="expireRules"
              label="Data de Validade"
              name="expire"
              type="tel"
              placeholder="##/##"
              return-masked-value
              v-mask="'##/##'"
              required
              filled
              rounded
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pt-0 pb-2">
            <v-text-field
              v-model="cvv"
              type="password"
              :rules="cvvRules"
              label="Código de Verificação"
              name="code"
              placeholder="###"
              v-mask="cvvMask"
              required
              filled
              rounded
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <div class="body-2 pa-3 pt-2">
          <v-alert type="info" color="primary" dense outlined>
            <p class="font-weight-bold">Você receberá duas contas separadas</p>
            No cartão você pagará pela geração da energia limpa para a Juntos.
            Você continuará pagando a conta da sua concessionária. Mas apenas os
            custos mínimos e/ou excedentes de consumo, encargos e impostos.
          </v-alert>
          <v-alert type="info" color="primary" dense outlined>
            Para segurança, nós iremos realizar uma pré autorização no valor de
            R$1,23 no seu cartão. Isso é apenas para verificação e o valor não
            será cobrado da sua fatura.
          </v-alert>
          <v-alert type="info" color="primary" dense outlined>
            Qualquer cobrança no seu cartão de crédito será realiza somente após
            a efetivação do cadastro e abatimento dos créditos de energia em sua
            conta de luz.
          </v-alert>
        </div>
        <div
          class="body-2 red--text font-weight-bold text-center pb-3"
          v-if="error_message"
        >
          {{ error_message }}
        </div>
        <v-btn
          color="secondary"
          class="mr-4 text-none mb-4"
          type="submit"
          :disabled="!valid || loading"
          :loading="loading"
          rounded
          block
          large
        >
          {{ "Cadastrar Cartão" }}
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { validExpire, getCardType } from "../../utils/creditcard";
import { createCard, cardHash, createCustomer } from "../../services/pagarme";

export default {
  data: () => ({
    payment_type: "boleto",
    cardNumberMaxLength: 19,
    cardName: "",
    nameRules: [
      (v) => !!v || "Ops! Por favor, informe o nome que está no cartão.",
      (v) =>
        (v && v.length >= 10) || "Informe o nome conforme cartão de crédito.",
    ],
    cardNumber: "",
    numberRules: [
      (v) => !!v || "Ops! Por favor, informe o número do cartão.",
      (v) => (v && v.length >= 10) || "Informe o número do cartão de crédito.",
      // v => /.+@.+\..+/.test(v) || 'Ops! Esse valide se você digitou o e-mail corretamente.',
    ],
    expire: "",
    expireRules: [
      (v) => !!v || "Por favor, informe a data de validade",
      (v) => (v && v.length > 4) || "Ops. Parece que esse não é um data valida",
      (v) =>
        (v && validExpire(v)) || "Ops. Parece que esse não é um data valida",
    ],
    cvv: null,
    cvvRules: [
      (v) => !!v || "Por favor, informe o código de validade do cartão",
      (v) => (v && v.length >= 3) || "Ops. Parece que esse não é código válido",
    ],
    valid: false,
    btn_label: "Concluir assinatura",
    card_type: "mastercard",
    backgroundImage: [String, Object],
    currentCardBackground: 13,
    amexCardMask: ["#### ###### #####"],
    dinersCardMask: ["#### ###### ####"],
    defaultCardMask: ["#### #### #### ####"],
    cardMask: ["#### #### #### ####"],
    cvvMask: ["###"],
    amexCvvMask: ["####"],
    dinersCvvMask: ["####"],
    loading: false,
    error_message: null,
  }),
  computed: {
    ...mapState(["user"]),
  },
  watch: {
    cardNumber(val) {
      if (!val || val === "" || val.length < 4) {
        this.card_type = "mastercard";
        return "mastercard";
      }
      let brand = getCardType(val);
      if (brand === "") return "mastercard";
      this.card_type = brand;
      if (brand === "amex") {
        this.cardMask = this.amexCardMask;
        this.cvvMask = this.amexCvvMask;
      }
      if (brand === "diners") {
        this.cardMask = this.dinersCardMask;
        this.cvv = this.dinersCvvMask;
      }
      return brand;
    },
  },
  methods: {
    ...mapActions(["saveUser", "updateUser"]),
    async submit() {
      //chama quando o método de pagamento é cartão
      if (this.$refs.form.validate()) {
        this.error_message = null;
        if (this.valid) {
          this.loading = true;
          this.createCreditCard();
        }
      }
    },
    maskCardNumber(cardNumber) {
      cardNumber = cardNumber.toString();
      let arr = cardNumber.split("");
      arr.forEach((element, index) => {
        if (index > 4 && index < 14 && element.trim() !== "") {
          arr[index] = "*";
        }
      });
      return arr.join("");
    },
    async createCreditCard() {
      let card = {};
      card.card_holder_name = this.cardName;
      card.card_expiration_date = this.expire.replace(/[^\d]/g, "");
      card.card_number = this.cardNumber.replace(/[^\d]/g, "");
      card.card_cvv = this.cvv;

      let buyer_id = this.user.buyer_id;
      let uid = this.user.customer_id;

      // Se não possuir buyer_id, criar buyer_id no pagarme
      if (!buyer_id) {
        const dataPagarme = {
          uid: this.user.customer_id,
          type: "individual",
          country: "br",
          name: this.user.name,
          email: this.user.email,
          phone: this.user.phone || null,
          birthday: this.user.date_of_birth || null,
          gender: this.user.gender || null,
          cpf: this.user.cpf,
        };
        let pagarme_user = await createCustomer(dataPagarme);
        if (pagarme_user.data.extensions) {
          console.error("Erro ao criar usuário no Pagarme");
          this.error_message = "Erro ao cadastrar cartão";
          this.loading = false;
        } else buyer_id = pagarme_user.data.id;
      }

      const hash = await cardHash(card);

      const ccData = {
        card_hash: hash,
        user_id: uid,
        is_default: true,
        is_temp: false,
      };
      try {
        await createCard(ccData)
          .then(async () => {
            await this.$swal({
              title: "Sucesso",
              text: "Seu cartão foi adicionado com sucesso!",
              icon: "success",
              confirmButtonText: "Ok",
            }).then(() => {
              this.$router.push("/energialimpa");
            });
          })
          .catch((err) => {
            this.error_message = "Erro ao cadastrar cartão";
            this.loading = false;
            this.$swal({
              title: "Erro",
              text: "Ocorreu um erro ao adicionar seu cartão, por favor tente novamente. Caso o erro persista por favor entre em contato com o suporte.",
              icon: "error",
              confirmButtonText: "Ok",
            });
            throw new Error(err);
          });
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
  },
};
</script>
