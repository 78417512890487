var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0", attrs: { fluid: "", wrap: "", "fill-height": "" } },
    [
      _c(
        "v-row",
        { staticStyle: { height: "100vh" } },
        [
          _c("v-col", {
            staticClass: "d-none d-md-block bg",
            attrs: { cols: "12", xs: "12", md: "6", lg: "4" }
          }),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "8", md: "6", lg: "4" } },
            [
              _c(
                "v-container",
                { staticClass: "pa-6" },
                [
                  _c("v-img", {
                    attrs: {
                      src: require("@/assets/novo/logo_roxo.svg"),
                      width: "230px"
                    }
                  }),
                  _c("h1", { staticClass: "pt-12" }, [
                    _vm._v(
                      "Pode falar pra todo mundo, " +
                        _vm._s(
                          _vm._f("firstName")(_vm.user ? _vm.user.name : "")
                        )
                    )
                  ]),
                  _c(
                    "h3",
                    [
                      _c("p", [
                        _vm._v(
                          "Muito bom ter você na comunidade da Juntos Energia"
                        )
                      ]),
                      _c(
                        "v-row",
                        { staticClass: "justify-center text-center" },
                        [
                          _c("v-img", {
                            attrs: {
                              src: require("@/assets/benefits/034-hydro-power.svg"),
                              "max-width": "230px"
                            }
                          })
                        ],
                        1
                      ),
                      _c("p", { staticClass: "body-1 font-weight-bold pt-3" }, [
                        _vm._v(
                          " A sua portabilidade foi concluída com sucesso! Você vai passar a consumir energia limpa no seu imóvel, pois a sua inscrição na Juntos foi concluída com sucesso. "
                        )
                      ]),
                      _c(
                        "p",
                        { staticClass: "body-1 font-weight-normal pt-3" },
                        [
                          _vm._v(
                            " Em breve você receberá um e-mail com os detalhes da sua adesão e próximos passos. "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "pt-1 pb-1" })
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "d-xl-flex pl-6 pr-6" },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _vm._v(
                      " Enquanto isso, baixe o app para indicar amigos e receber ainda mais desconto na sua conta "
                    )
                  ]),
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://apps.apple.com/br/app/enercred/id1465641135",
                          target: "_blank"
                        }
                      },
                      [
                        _c("v-img", {
                          attrs: {
                            src: require("@/assets/app/app-store-badge.png"),
                            "max-width": "200"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://play.google.com/store/apps/details?id=com.enecred.enercredapp&hl=pt_BR&gl=US",
                          target: "_blank"
                        }
                      },
                      [
                        _c("v-img", {
                          attrs: {
                            src: require("@/assets/app/play-store-badge.png"),
                            "max-width": "200"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-col", {
            staticClass: "d-none d-md-block bg",
            attrs: { cols: "12", xs: "12", md: "6", lg: "4" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }