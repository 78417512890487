var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-8 pb-6", attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-row",
        { staticClass: "howworks", attrs: { align: "center" } },
        [
          _c(
            "v-col",
            {
              staticClass: "pa-lg-16 pa-sm-6 pb-sm-0",
              attrs: { cols: "12", md: "6" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "pr-lg-16 pl-lg-16 pt-lg-12 pa-sm-6",
                  attrs: { cols: "12", lg: "12" }
                },
                [
                  _c("h2", { staticClass: "blue--text d-lg-none" }, [
                    _vm._v("Saiba mais")
                  ]),
                  _c("h1", { staticClass: "blue--text d-none d-lg-block" }, [
                    _vm._v("Saiba mais")
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pa-lg-16 pa-sm-3", attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-list",
                { attrs: { flat: "" } },
                _vm._l(_vm.items, function(item, i) {
                  return _c(
                    "v-list-item",
                    { key: i },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-img", {
                            attrs: {
                              src: require("@/assets/benefits/" + item.icon),
                              width: "60px"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-list-item-content", [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(item.text) }
                        })
                      ])
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "pl-16 pr-16",
                      attrs: {
                        color: "primary",
                        href: "#simular",
                        large: "",
                        rounded: ""
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.config.call_to_action || "SIMULE AGORA")
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }