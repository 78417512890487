<template>
    <div>
        <v-container class="text-center justify-center pt-0 mt-0">
            <v-row>
                <v-col cols="12" class="pt-0 mt-0">
                    
                    <h2 class="pt-0">{{firstName !== '' ? firstName + ',': ''}} <strong style="color:blue">&nbsp;economize&nbsp;</strong> até</h2>
                    <h1>R$ {{this.economy | formatCurrency}}</h1>
                    <div class="subtitle">{{economy_label}}</div>
                </v-col>
            </v-row>
            <v-row class="text--center justify-center pt-0 mt-0">
                <v-col cols="6" class="text--center justify-center">
                    <div class="body-2 pt-2 pb-2 black--text" style="background: #efefef; border-radius: 20px">
                        R$ <span class="font-weight-bold">{{(this.economy / 12) | formatCurrency}}</span> por mês
                    </div>
                </v-col>
            </v-row>
            <v-row class="text--center justify-center caption pt-1 mt-0">
                <plan-conditions/>
            </v-row>
            <v-row>
                <v-col cols="12" >
                    <span class="subtitle-2">Você contribui para o planeta com</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" align="center">
                    <v-img src="@/assets/benefits/carbon-dioxide.svg" width="60%"/>
                    <!-- <v-icon x-large
                    >
                        mdi-molecule-co2
                    </v-icon> -->
                    <h2>{{enviroments.co2ton?enviroments.co2ton.toFixed(0):0}}</h2>
                    <div class="subtitle">tons de redução de co2</div>
                </v-col>
                <v-col cols="4" align="center">
                    <!-- <v-icon x-large
                    >
                        mdi-tree-outline
                    </v-icon> -->
                    <v-img src="@/assets/benefits/trees.svg" width="60%"/>
                    <h2>{{enviroments.trees.toFixed(0)}}</h2>
                    <div class="subtitle">árvores plantadas</div>
                </v-col>
                <v-col cols="4"  align="center">
                    <!-- <v-icon x-large
                    >
                        mdi-car-multiple
                    </v-icon> -->
                    <v-img src="@/assets/benefits/oil-barrel.svg" width="60%"/>
                    <h2>{{enviroments.oilBarrel.toFixed(0)}}</h2>
                    <div class="subtitle">redução barris de petróleo</div>
                </v-col>
            </v-row>
            
            <v-row class="text--center justify-center pt-2 mt-0" v-if="lead && lead.origin_type === 'direct' && !cupom">
                <v-col cols="12">
                    <coupon-form v-if="lead && lead.origin_type === 'direct'"/>
                </v-col>
            </v-row>
            <v-row class="text--center justify-center pt-2 mt-0" v-else-if="(lead && lead.coupon && lead.coupon.coupon) || (cupom)">
                <v-col cols="12">
                    <span class="subtitle-1">Você ativou o cupom <span class="text-uppercase font-weight-bold">{{(lead && lead.coupon && lead.coupon.coupon) || cupom.coupon}}</span></span>
                    <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click.stop="delCoupon"><v-icon dark color="black">mdi-trash-can-outline</v-icon></v-btn>
                    </template>
                    <span>Remover Cupom</span>
                    </v-tooltip>
                    
                </v-col>
                <!-- lead.coupon: {{cupom}} -->
                <v-col cols="8" class="text--center justify-center pa-0">
                    <div 
                        v-if="lead && lead.coupon && lead.coupon.custom_text" v-html="lead.coupon.custom_text"
                        class="body-2 pa-3 ma-0 black--text" 
                        style="background: #b2f7bf; border-radius: 30px"
                    />
                    <div 
                        v-else-if="cupom && cupom.coupon" v-html="cupom.custom_text"
                        class="body-2 pa-3 ma-0 black--text" 
                        style="background: #b2f7bf; border-radius: 30px"
                    />
                    <div v-else class="body-2 pa-3 ma-0 black--text" style="background: #b2f7bf; border-radius: 30px">
                        economiza mais R$ <span class="font-weight-bold">{{((lead && lead.coupon && lead.coupon.discount) || cupom.discount) | formatCurrency}}</span><br> na primeira fatura
                    </div>
                </v-col>
            </v-row>

        </v-container>
        <how-works/>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { eventBus } from "../../main"
import PlanConditions from '../Plans/PlanConditions.vue'
import CouponForm from './CouponForm.vue'
import HowWorks from './HowWorks.vue'
export default {
    props:["discount"],
    components: {
        HowWorks,
        CouponForm,
        PlanConditions
    },
    computed: {
        ...mapState(['lead', 'enviroments', 'coupon']),
        firstName() {
            if(this.lead && this.lead.name) return this.lead.name.split(' ')[0]
            else return ''
        }
    },
    data: function () { 
        return {
            discount_value: this.discount,
            toggle_exclusive: 0,
            economy: null,
            economy_label: 'por ano e',
            benefits: [{
                text: 'Na redução de emissão de <h1>250kg(s)</h1> de co2',
                img: require('@/assets/benefits/co2.jpg'),
                background: 'blue'
            },
            {
                text: 'Isso também equivale ao plantio de <h1>197</h1> arvore(s).',
                img: require('@/assets/benefits/arvores.jpg'),
                background: 'green'
            },
            {
                text: 'E a redução de <h1>28</h1> barril(s) de petróleo.',
                img: require('@/assets/benefits/barris.jpg'),
                background: 'green'
            },
            {
                text: 'E você ainda economiza aproximadamente <h1>R$ 300,00</h1> por ano.',
                img: require('@/assets/benefits/economia.jpg'),
                background: 'green'
            }],
            cupom: null
        }
    },
    mounted() {
        if(this.lead) {
            // let percentage_discount = this.lead.person_type === 'pf' ? 0.15 : 0.2
            this.economy = (this.lead.average_consumption * (1 - 0.09) - 50) * (this.discount_value || 0.15) * 12
            if(this.lead.coupon) this.cupom = this.lead.coupon
        }
    },
    watch: {
        discount(val) {
            this.economy = (this.lead.average_consumption * (1 - 0.09) - 50) * (val || 0.15) * 12
        },
        discount_value(val) {
            this.economy = (this.lead.average_consumption * (1 - 0.09) - 50) * (val || 0.15) * 12
        },
        lead() {
            this.economy = (this.lead.average_consumption * (1 - 0.09) - 50) * (this.discount_value || 0.15) * 12
        },
        coupon(val) {
            this.cupom = val
            if(val && val.percentage_discount) this.discount_value = (val.percentage_discount / 100)
            // this.$emit("udpate_discount", 20) ;
            // this.discount = 20
        }
    },
    methods: {
        toggle_economy(toggle) {
            if(!toggle) {
                this.economy = (((this.lead?this.lead.average_consumption:this.economy) * .85) / 12).toFixed(0)
                this.economy_label = 'por mês'
            } else {
                this.economy = ((this.lead?this.lead.average_consumption:this.economy) * .85).toFixed(0)
                this.economy_label = 'por ano e'
            }
        },
        showHowWorks() {
            eventBus.$emit('open-dialog_howworks')
            // Grava abertura de página no Amplitude
            // var eventProperties = {
            //     "page": 'howWorks'
            // };
            // this.$amplitude.logEvent('VIEWED', eventProperties);
        },
        delCoupon() {
            this.lead.coupon = null
            this.cupom = null
        }
    }
}
</script>