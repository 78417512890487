<template>
    <v-container fluid fill-height class="pt-8 pb-8">
        <v-row align="center" class="ourmembers">
            <v-col cols="12" sm="12" md="12" lg="12">
                <div class="pa-12">
                    <h1 style="color:#FFF">Faça parte dessa comunidade que economiza e ajuda o planeta</h1>
                    <h2 class="font-weight-light blue--text pt-8 d-none d-lg-block">Orgulho de como nossos membros estão construindo um futuro mais sustentável economicamente, ambientalmente e socialmente.</h2>
                </div>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4">
                <iframe width="100%" height="220" src="https://www.youtube.com/embed/0PVzZVgoolM?enablejsapi=1&amp;origin=https%3A%2F%2Fenercred.com.br" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-30051236_3="true" id="453571096" data-gtm-yt-inspected-1_25="true"></iframe>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4">
                <iframe width="100%" height="220" src="https://www.youtube.com/embed/aTF-JUDkk08?enablejsapi=1&amp;origin=https%3A%2F%2Fenercred.com.br" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-30051236_3="true" id="481339508" data-gtm-yt-inspected-1_25="true"></iframe>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4">
                <iframe width="100%" height="220" src="https://www.youtube.com/embed/n_o0frzSyaE?enablejsapi=1&amp;origin=https%3A%2F%2Fenercred.com.br" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-30051236_3="true" id="298303397" data-gtm-yt-inspected-1_25="true"></iframe>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
                <div class="text-center"><v-btn outlined color="white" href="#simular" large rounded class="pl-16 pr-16">{{config.call_to_action || 'SIMULE AGORA'}}</v-btn></div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
  export default {
    props: ['config']
  }
</script>
<style lang="scss" scoped>
    .ourmembers {
        min-height: 100vh;
        // min-height: 1000px;
    }
</style>
<style scoped>
    
</style>