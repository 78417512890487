var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "justify-md-center",
      staticStyle: { "background-color": "#FFF" }
    },
    [
      _c(
        "div",
        {
          staticClass: "pt-5 pr-10 pl-10 text-center title",
          staticStyle: { "word-break": "break-word" }
        },
        [
          _vm._v(" Oie, "),
          _c("strong", [
            _vm._v(_vm._s(_vm._f("firstName")(_vm.lead.name)) + "!")
          ])
        ]
      ),
      _c(
        "v-card",
        {
          staticClass: "flex-row justify-center mb-6",
          attrs: { flat: "", tile: "" }
        },
        _vm._l(_vm.plans, function(item) {
          return _c(
            "v-card",
            {
              key: item.id,
              class:
                (_vm.plan && _vm.plan.id === item.id) ||
                (!_vm.plan && item.recommended)
                  ? "card_selected pt-1 pb-1 rounded-xl ma-6 justify-center"
                  : "card pt-1 pb-1 rounded-xl ma-6 justify-center",
              attrs: {
                elevation: "0",
                dark:
                  (_vm.plan && _vm.plan.id === item.id) ||
                  (!_vm.plan && item.recommended)
              },
              on: {
                click: function($event) {
                  return _vm.toggle_plan(item)
                }
              }
            },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "justify-center",
                  attrs: { "primary-title": "" }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "pt-0 mt-0 ma-0 pr-0 pl-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-2 pb-2 pr-0 pl-0 mt-0 text-center",
                          attrs: { cols: "12" }
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "pt-0 mt-0 ma-0" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-0 mt-0 text-center",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-center pt-1 mt-0 pb-0 mb-0"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "pa-2 body-3 text-break"
                                        },
                                        [
                                          _vm._v("Você "),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "#000",
                                                background: "#17e380",
                                                padding: "5px",
                                                "border-radius": "4px"
                                              }
                                            },
                                            [_vm._v("vai economizar")]
                                          ),
                                          _vm._v(
                                            " mais de uma conta de luz por ano"
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-center pt-1 mt-0 pb-0 mb-0 subtitle font-type"
                                    },
                                    [_vm._v("ECONOMIA ANUAL")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-center pt-4 mt-2 pb-0 mb-0"
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "economyCurrency pt-0" },
                                        [_vm._v("R$")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "economyValueTotal text-center font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatCurrencyInteger")(
                                                _vm.calcEconomyY(
                                                  item.discount * 1
                                                )
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("p", { staticClass: "caption text-center" }, [
                            item.id == "liquidaenergiapj" ||
                            item.id == "liquidaenergiapf"
                              ? _c("span", [_vm._v("PROMO")])
                              : _vm._e(),
                            _vm.cupom &&
                            _vm.cupom.percentage_discount &&
                            _vm.cupom.percentage_discount > item.discount
                              ? _c(
                                  "span",
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("formatCurrencyInteger")(
                                            _vm.cupom.percentage_discount
                                          )
                                        ) +
                                        "% OFF "
                                    ),
                                    _c(
                                      "v-chip",
                                      {
                                        attrs: { color: "#ed0c5e", small: "" }
                                      },
                                      [_vm._v(_vm._s(_vm.cupom.coupon))]
                                    )
                                  ],
                                  1
                                )
                              : _c("span", [
                                  _vm._v(
                                    " (" +
                                      _vm._s(
                                        _vm._f("formatCurrencyInteger")(
                                          item.discount
                                        )
                                      ) +
                                      "% OFF) "
                                  )
                                ]),
                            item.id == "liquidaenergiapj" ||
                            item.id == "liquidaenergiapf"
                              ? _c("span", [_vm._v("*")])
                              : _vm._e()
                          ]),
                          _c(
                            "p",
                            {
                              staticClass:
                                "body-3 text-left ma-0 pl-14 text-break"
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "pr-2", attrs: { small: "" } },
                                [_vm._v("mdi-account-hard-hat-outline")]
                              ),
                              _vm._v("Sem instalações")
                            ],
                            1
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "body-3 text-left ma-0 pl-14 text-break"
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "pr-2", attrs: { small: "" } },
                                [_vm._v("mdi-currency-usd")]
                              ),
                              _vm._v("Sem taxa de adesão")
                            ],
                            1
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "body-3 text-left ma-0 pl-14 text-break"
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "pr-2", attrs: { small: "" } },
                                [_vm._v("mdi-file-sign")]
                              ),
                              _vm._v(
                                _vm._s(
                                  item.contract_time > 0
                                    ? "Fidelidade de " + item.canceled_time
                                    : "Sem fidelidade"
                                )
                              )
                            ],
                            1
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "body-3 text-left mb-2 pl-14 text-break"
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "pr-2", attrs: { small: "" } },
                                [_vm._v("mdi-auto-fix")]
                              ),
                              _vm._v("Sem pegadinhas")
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none text-break",
                              attrs: { color: "#ed0c5e", rounded: "", dark: "" }
                            },
                            [_vm._v("Checar compatibilidade do meu imóvel")]
                          ),
                          _c("como-funciona", { attrs: { plan: item } }),
                          item.id == "liquidaenergiapj" ||
                          item.id == "liquidaenergiapf"
                            ? _c(
                                "p",
                                { staticClass: "caption desc pb-0 mb-0" },
                                [
                                  _vm._v(
                                    " " + _vm._s(item.percentage_note) + " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }