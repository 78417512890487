var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        transition: "dialog-bottom-transition",
        scrollable: "",
        persistent: ""
      },
      model: {
        value: _vm.dialog_qualification_details,
        callback: function($$v) {
          _vm.dialog_qualification_details = $$v
        },
        expression: "dialog_qualification_details"
      }
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { justify: "space-around" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "div",
                    { staticClass: "pa-6 pt-12 justify-center text-left" },
                    [
                      _c(
                        "h1",
                        { class: _vm.typeLabel.color + "--text" },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: { right: "", color: _vm.typeLabel.color }
                            },
                            [_vm._v(" " + _vm._s(_vm.typeLabel.icon) + " ")]
                          ),
                          _vm._v(" " + _vm._s(_vm.uc) + " ")
                        ],
                        1
                      ),
                      _c("h3", { staticClass: "pt-1" }, [
                        _vm._v(_vm._s(_vm.typeLabel.lable))
                      ]),
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(_vm.typeLabel.description)
                        }
                      })
                    ]
                  ),
                  _vm.type === "NOT_ACCOUNT"
                    ? _c(
                        "div",
                        { staticClass: "pa-4" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-file-input", {
                                    attrs: {
                                      accept: "image/*",
                                      label: "Anexar Conta"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.detectFiles($event)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "text-center" },
                            [
                              _c("v-col", { attrs: { cols: "6" } }),
                              _c("v-spacer"),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary", rounded: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.sendBill()
                                        }
                                      }
                                    },
                                    [_vm._v(" Enviar Conta ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    { staticClass: "pa-6" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-6 mb-6 justify-center",
                          attrs: { outlined: "", block: "", rounded: "" },
                          on: {
                            click: function($event) {
                              _vm.dialog_qualification_details = false
                            }
                          }
                        },
                        [_vm._v(" fechar ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }