var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { staticClass: "my-3", attrs: { text: "", small: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "div",
                        { staticClass: "text-decoration-underline text-none" },
                        [_vm._v(" Entenda como funciona ")]
                      )
                    ]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog_como_funciona,
            callback: function($$v) {
              _vm.dialog_como_funciona = $$v
            },
            expression: "dialog_como_funciona"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("iframe", {
                attrs: {
                  width: "100%",
                  height: "198",
                  src: "https://www.youtube.com/embed/EI6rTIbp-r4",
                  title: "Juntos Energia",
                  frameborder: "0",
                  allow:
                    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
                  allowfullscreen: ""
                }
              }),
              _c(
                "p",
                {
                  staticClass:
                    "text-h6 pa-3 primary--text font-weight-bold text-center"
                },
                [_vm._v("Como receber energia sem instalar equipamentos?")]
              ),
              _c("v-card-text", { staticClass: "text-justify" }, [
                _vm._v(
                  "Conectamos produtores de energia limpa e sustentável a apês, casas e empresas. Enviamos a energia para a rede da sua concessionária e eles entregam para você. Não é necessário investir em placas e pagar nada por essa portabilidade. É digital e gratuita!"
                )
              ]),
              _c("v-img", {
                staticClass: "center",
                attrs: {
                  src: require("../../assets/howworks/como_funciona.png")
                }
              }),
              _c("v-divider", { staticClass: "mt-5 mb-5" }),
              _c(
                "div",
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-h6 py-0 mb-0 primary--text font-weight-bold text-center"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.plan.contract_time > 0
                              ? "Fidelidade de " + _vm.plan.canceled_time
                              : "Sem fidelidade"
                          ) +
                          " "
                      )
                    ]
                  ),
                  _vm.plan.id == "liquidaenergiapj" ||
                  _vm.plan.id == "liquidaenergiapf"
                    ? _c("v-card-text", { staticClass: "text-justify" }, [
                        _vm._v(
                          _vm._s(
                            _vm.plan.cancelation_conditions
                              .contract_canceled_clause
                          )
                        )
                      ])
                    : _c("v-card-text", { staticClass: "text-justify" }, [
                        _vm._v(
                          "Caso queira cancelar, só precisa fazer um aviso prévio de 60 dias para que a gente possa fazer a reversão da portabilidade na concessionária. É o prazo exigido pela concessionária para o desligamento. Mas pode confiar! O atendimento é por telefone ou Whatsapp e não tem perrengue. Queremos te provar que é possível receber um bom atendimento em um prestador de serviços de energia! (a gente não tava acostumado com isso, né?)"
                        )
                      ])
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog_como_funciona = false
                        }
                      }
                    },
                    [_vm._v(" Fechar ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }