var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "pa-0 home" },
        [
          _c(
            "v-img",
            {
              staticClass: "ma-0 justify-center align-center pb-10",
              attrs: {
                src:
                  _vm.landingPageConfig && _vm.landingPageConfig.background
                    ? _vm.landingPageConfig.background
                    : require("@/assets/usina-bg2.jpg"),
                fluid: ""
              }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", xs: "12", md: "6", lg: "6" } },
                    [
                      _c(
                        "v-container",
                        { attrs: { "pl-md-8": "", "pl-lg-16": "" } },
                        [
                          _c(
                            "div",
                            { staticClass: "pa-12 pl-4 pt-xs-2 pt-md-0 ma-0" },
                            [
                              _c("v-img", {
                                attrs: {
                                  src: require("@/assets/novo/juntos_white_600px.png"),
                                  width: "280px"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { staticClass: "banner", attrs: { row: "" } },
                            [
                              _c("banner-simulador", {
                                attrs: { config: _vm.landingPageConfig }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-4",
                      staticStyle: { "background-color": "transparent" },
                      attrs: { cols: "12", md: "6", lg: "6", id: "simular" }
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-layout",
                            { staticClass: "banner", attrs: { row: "" } },
                            [
                              _c("consumption-form"),
                              _c(
                                "v-dialog",
                                {
                                  attrs: {
                                    fullscreen: "",
                                    "hide-overlay": "",
                                    transition: "dialog-bottom-transition",
                                    scrollable: ""
                                  },
                                  model: {
                                    value: _vm.dialog_lead_form,
                                    callback: function($$v) {
                                      _vm.dialog_lead_form = $$v
                                    },
                                    expression: "dialog_lead_form"
                                  }
                                },
                                [_c("lead-form")],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" aaaa "),
      _c("how-works", { attrs: { config: _vm.landingPageConfig } }),
      _c("our-members", {
        staticClass: "ourmembers",
        attrs: { config: _vm.landingPageConfig }
      }),
      _c("about-enercred", {
        staticClass: "d-none d-md-block",
        attrs: { config: _vm.landingPageConfig }
      }),
      _c("about-enercred-mobile", {
        staticClass: "d-md-none",
        attrs: { config: _vm.landingPageConfig }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }