<template>
     <v-container class="pa-0">
        <v-card
            class="d-flex justify-center align-center mb-6"
            flat
            tile
            height="100vh"
        >
            <v-card
                class="pa-2"
                color="#FFF"
                outlined
                flat
                tile
            >
                <v-row class="justify-center pb-8">
                    <v-img 
                        src="@/assets/novo/Logo-novo.svg" 
                        max-height="180"
                        max-width="200"
                    />
                </v-row>
                <v-row class="justify-center">
                    <v-img 
                        src="@/assets/cards/payment_confirm.png" 
                        max-height="300"
                        max-width="300"
                    />
                </v-row>
                <v-row class="justify-center text-center ma-3">
                    <h2>Método de pagamento cadastrado com sucesso!</h2>
                </v-row>
                <v-row class="justify-center text-center ma-12">
                    <v-col cols="12">
                        <social-media/>
                    </v-col>
                </v-row>
                
            </v-card>
        </v-card>
     </v-container>
</template>
<script>
import { mapState } from 'vuex'
import SocialMedia from '../components/SocialMedia.vue'
export default {
  components: { SocialMedia },
    computed: {
        ...mapState(['user'])
    },
}
</script>