var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-form",
        {
          ref: "form",
          staticClass: "ml-2 mr-2",
          attrs: { "lazy-validation": "", name: "simulador-onboarding" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-row",
            { staticClass: "d-flex pt-2 pb-0" },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("p", { staticClass: "font-weight-bold text-center" }, [
                  _vm._v(
                    "Falta pouco! Informe os dados abaixo e veja o quanto você pode economizar"
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "d-flex" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        rounded: "",
                        dark: "",
                        block: "",
                        outlined: "",
                        color: "black"
                      },
                      on: {
                        click: function($event) {
                          return _vm.socialLogin("google")
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-google")
                      ]),
                      _vm._v(" Simular com Google ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [_c("span", { staticClass: "caption" }, [_vm._v("OU")])]
              )
            ],
            1
          ),
          _c("v-text-field", {
            attrs: {
              label: "Nome Completo",
              required: "",
              rules: _vm.nameRules,
              disabled:
                _vm.isSocialLogin &&
                _vm.lead.hasOwnProperty("name") &&
                _vm.lead.name !== null,
              filled: "",
              rounded: "",
              autofocus: "",
              dense: ""
            },
            on: { change: _vm.uppercaseName },
            model: {
              value: _vm.name,
              callback: function($$v) {
                _vm.name = $$v
              },
              expression: "name"
            }
          }),
          _c("v-text-field", {
            staticClass: "email",
            attrs: {
              rules: _vm.emailRules,
              label: "E-mail",
              "prepend-icon": "mdi-email",
              name: "email",
              type: "email",
              disabled:
                _vm.isSocialLogin &&
                _vm.lead.hasOwnProperty("email") &&
                _vm.lead.email !== null,
              required: "",
              filled: "",
              rounded: "",
              dense: ""
            },
            on: { keyup: _vm.lowercase },
            model: {
              value: _vm.email,
              callback: function($$v) {
                _vm.email = $$v
              },
              expression: "email"
            }
          }),
          _c("v-text-field", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: ["(##) #####-####"],
                expression: "['(##) #####-####']"
              }
            ],
            attrs: {
              type: "tel",
              rules: _vm.phoneRules,
              disabled:
                _vm.isSocialLogin &&
                _vm.lead.hasOwnProperty("phone") &&
                _vm.lead.phone !== null,
              "prepend-icon": "mdi-phone",
              label: "Celular",
              placeholder: "(##)#####-####",
              required: "",
              filled: "",
              rounded: "",
              dense: ""
            },
            model: {
              value: _vm.phone,
              callback: function($$v) {
                _vm.phone = $$v
              },
              expression: "phone"
            }
          }),
          _c("v-text-field", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: ["#####-###"],
                expression: "['#####-###']"
              }
            ],
            attrs: {
              rules: _vm.zipcodeRules,
              label: "CEP",
              "prepend-icon": "mdi-map-marker-minus-outline",
              name: "cep",
              type: "tel",
              required: "",
              filled: "",
              rounded: "",
              dense: ""
            },
            on: {
              change: function($event) {
                return _vm.buscaCep($event)
              }
            },
            model: {
              value: _vm.zipcode,
              callback: function($$v) {
                _vm.zipcode = $$v
              },
              expression: "zipcode"
            }
          }),
          _c("v-text-field", {
            ref: "cupom",
            attrs: {
              label: "Cupom de desconto (Opcional)",
              "prepend-icon": "mdi-sale",
              name: "cupom",
              required: "",
              filled: "",
              rounded: "",
              dense: ""
            },
            on: {
              keyup: _vm.uppercase,
              keypress: function($event) {
                _vm.isValidCoupon = true
              },
              keydown: function($event) {
                _vm.isValidCoupon = true
              }
            },
            model: {
              value: _vm.cupom,
              callback: function($$v) {
                _vm.cupom = $$v
              },
              expression: "cupom"
            }
          }),
          _c("v-autocomplete", {
            attrs: {
              items: _vm.filteredUtilityCompanies,
              label: "Concessionária",
              "item-text": "text",
              "item-value": "value",
              required: "",
              filled: "",
              rounded: "",
              dense: ""
            },
            model: {
              value: _vm.utility,
              callback: function($$v) {
                _vm.utility = $$v
              },
              expression: "utility"
            }
          }),
          _vm.isValidCoupon === false
            ? _c(
                "div",
                { staticClass: "pt-0 pb-3 caption text-center red--text" },
                [_vm._v(" " + _vm._s(_vm.couponMessage) + " ")]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "body-2 pb-6 pt-0" },
            [
              _c("v-checkbox", {
                attrs: {
                  label:
                    "Quero receber mais informações para economizar a minha conta"
                },
                model: {
                  value: _vm.optin,
                  callback: function($$v) {
                    _vm.optin = $$v
                  },
                  expression: "optin"
                }
              })
            ],
            1
          ),
          _vm.error_message
            ? _c("div", { staticClass: "text-center red--text" }, [
                _vm._v(" " + _vm._s(_vm.error_message) + " ")
              ])
            : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass: "mr-4",
              attrs: {
                color: "primary",
                type: "submit",
                disabled: !_vm.valid || _vm.loading || !_vm.isValidCoupon,
                loading: _vm.loading,
                rounded: "",
                block: "",
                large: ""
              },
              on: { click: _vm.submit }
            },
            [_vm._v(" Continuar ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }