<template>
    <!-- <v-layout row> -->
        <v-card flat>
            <v-container>
                <v-row>
                    <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="text-center"
                    >
                        <h2>Validação</h2>
                    </v-col>
                </v-row>
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent="submit"
                    class="ml-3 pr-6 mr-3"
                >
                    <v-row v-if="!code_validation">
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="text-left"
                        >
                            Para garantirmos uma comunicação efetiva com você precisamos validar seu celular ou e-mail, escolha o meio que deseja receber nosso código de validação
                        </v-col>
                    </v-row>
                    <v-row class="pt-0" v-if="!code_validation">
                        <v-col
                            cols="6"
                            sm="6"
                            md="6"
                            class="pt-0"
                        >
                            <v-btn 
                                large 
                                rounded 
                                block 
                                outlined 
                                small 
                                color="black" 
                                @click.prevent="submit('sms')" 
                                class="ma-2"
                                :disabled="loading"
                            >
                                SMS
                                <v-icon right>mdi-cellphone-message</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col
                            cols="6"
                            sm="6"
                            md="6"
                            class="pt-0"
                        >
                            <v-btn 
                                large 
                                rounded 
                                block 
                                outlined 
                                small 
                                color="black" 
                                @click="submit('email')" 
                                class="ma-2"
                                :disabled="loading"
                            >
                                E-mail
                                <v-icon right>mdi-email-check-outline</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="12 pl-9" v-if="error_message">
                            <p class="red--text body-1 text-center">Ops! {{this.error_message}}!</p>
                            <v-btn href="https://api.whatsapp.com/send/?phone=5535998625580&text=Ol%C3%A1!" target="_blank" outlined rounded block color="green" :disabled="loading">Falar com um consultor</v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="code_validation">
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="pt-0"
                        >
                            Informe o código enviado ao seu {{code_validation==='sms'?'celular ' + lead.phone:'e-mail ' + lead.email}}
                        </v-col>
                        <v-col
                            cols="8"
                            sm="8"
                            md="8"
                            class="pt-0"
                        >
                            <v-text-field
                                v-model="code"
                                label="Código de Validação"
                                required
                                v-mask="'######'"
                                :rules="codeRules"
                                filled
                                rounded
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="4"
                            sm="4"
                            md="4"
                            class="pt-0"
                        >
                            <v-btn 
                                large v-if="countDown <= 0" rounded block plain @click="code_validation = null" class="mt-2 ml-0"
                                :loading="loading"
                            >
                                <v-icon left>mdi-reload</v-icon>
                                Reenviar
                            </v-btn>
                            <span v-else>Reenviar em  {{ countDown }}s</span>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="pt-0 text-center"
                        >
                            <p class="body-1 red--text text-center" v-if="error_message">{{error_message}}</p>
                            <v-btn large rounded block color="primary" class="ma-2" @click="validation()">
                                Validar
                            </v-btn>
                        </v-col>
                    </v-row>
                    <!-- <v-row class="pt-0">
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                            class="pt-0"
                        >
                            <v-btn rounded block color="primary" :disabled="!valid" @click="submit">ENVIAR</v-btn>
                        </v-col>
                    </v-row> -->
                </v-form>
            </v-container>
        </v-card>
    <!-- </v-layout> -->
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { sendOTP } from '../services/otp'
import { getLead } from '../services/leads'
import md5 from 'md5'
// import Benefits from '../components/Simulador/Benefits.vue'
export default {
    mounted() {
      if(this.lead) {
        this.cpf = this.lead.cpf
      }
    },
    data: () => ({
        code_validation: null,
        code: null,
        codeRules: [
            v => !!v || 'Ops! Por favor, informe o Código recebido.',
            v => (v && v.length >= 6) || 'Informe o código de 6 digitos.',
        ],
        countDown : 60,
        valid: false,
        loading: false,
        error_message: null
    }),
    computed: {
        ...mapState(['lead']),
        msg_validation() {
            if(this.lead) {
                if(this.code_validation === 'sms') return 'Enviaremos um código de validação por SMS para o número ' + this.lead.phone
                else return 'Enviaremos um código de validação no email ' + this.lead.email
            } else {
                return ''
            }
        }
    },
    methods: {
      ...mapActions(['saveLead', 'facebookSignUp', 'googleSignUp']),
      submit(type) {
            this.error_message = null
            this.loading = true
            let user = {
                ...this.lead,
                cpf: this.cpf,
                titular: this.titular,
                code_validation: this.code_validation
            }
            let data = {
                email: user.email, 
                type: type, 
                name: user && user.name?user.name.split(' ')[0]:this.lead.name,
                phone: user.phone
            }
            // Se tiver em ambiente de testes pula a validação do OTP
            // if(process.env.NODE_ENV === 'development') {
            //     let _lead = {
            //         email: this.lead.email,
            //         otp_valid: true
            //     }
            //     this.saveLead(_lead)
            //     this.$emit('startContract')
            // }
            sendOTP(data)
                .then(res => {
                    if(res.status === 200) {
                        this.code_validation = type
                        this.countDown = 60
                        this.loading = false
                        this.countDownTimer()
                        // Grava evento no Amplitude
                        // var eventProperties = {
                        //     category: "onboard",
                        //     action: "send",
                        //     label: "Validação de OTP Enviado",
                        //     value: this.type,
                        //     step: this.$route.name,
                        // };
                        // this.$amplitude.logEvent('send_OTP', eventProperties);
                    } else {
                        this.loading = false
                        this.error_message = 'Erro ao enviar mensagem'
                    }

                })
                .catch(err => {
                    this.loading = false 
                    this.error_message = 'Erro ao enviar mensagem'
                    // Temporario apenas para testes
                    console.error(err)
                })
      },
      countDownTimer() {
            if(this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            }
        },
        validation() {
            this.error_message = null
            this.loading = true
            getLead(this.lead.email)
                .then(lead => {
                    if(lead && lead.data().otp === md5(this.code)) {
                        let _lead = {
                            email: this.lead.email,
                            otp_valid: true,
                            step: 'OTP Validado',
                        }
                        this.saveLead(_lead)
                        this.$emit('startContract')
                        // Grava evento no Amplitude
                        // var eventProperties = {
                        //     category: "onboard",
                        //     action: "validated",
                        //     label: "Validação de OTP Concluida",
                        //     value: this.type,
                        //     step: this.$route.name,
                        // };
                        // this.$amplitude.logEvent('validated_OTP', eventProperties);
                        // this.loading = false
                        // this.$router.push('unidades_consumidoras')
                    } else {
                        this.loading = false
                        this.error_message = 'Código inválido'
                    }
                })
                .catch(err => {
                    this.loading = false
                    this.error_message = 'Erro ao validar mensagem'
                    console.error(err.message)
                })
            
        }
    }

}
</script>
