<template>
  <v-container fluid class="pa-0">
    <v-row class="pa-0 home">
      <v-img :src="landingPageConfig && landingPageConfig.background ? landingPageConfig.background : require('@/assets/usina-bg2.jpg')" fluid class="ma-0 justify-center align-center pb-10">
        <v-row>
          <v-col cols="12" xs="12" md="6" lg="6">
            <v-container pl-md-8 pl-lg-16>
              <div class="pa-12 pl-4 pt-xs-2 pt-md-0 ma-0">
                <v-img src="@/assets/novo/juntos_white_600px.png" width="280px"/>
              </div>
              <v-layout row class="banner">
                <v-container>
                  <v-row class="ml-1 mr-1">
                    <v-col class="ma-1">
                      <!-- Desktop -->
                      <h1 class="font-weight-medium d-none d-md-block">
                        Como é bom ter amigos de verdade
                      </h1>
                      <div class="pt-8 d-none d-md-block">
                        <!-- Saiba como funciona <v-icon rigth color="#fff">mdi-arrow-right</v-icon> -->
                        <h2 class="font-weight-light blue--text">Você foi indicado por {{indicator.name | firstName}} e agora pode economizar mais de 1 conta de luz todos os meses. Sem obras ou taxas de adesão.</h2>
                      </div>
                      <!-- Mobile and Tablet -->
                      <h2 class="font-weight-medium d-md-none">
                        Como é bom ter amigos de verdade
                      </h2>
                      <div class="pt-8 d-flex d-md-none">
                        <!-- Saiba como funciona <v-icon rigth color="#fff">mdi-arrow-right</v-icon> -->
                        <h3 :class="'font-weight-light blue--text'">Você foi indicado por {{indicator.name}} e agora pode economizar mais de 1 conta de luz todos os meses. Sem obras ou taxas de adesão.</h3>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-layout>
            </v-container>
          </v-col>
          <v-col cols="12" md="6" lg="6" class="pa-4" style="background-color: transparent"  id="simular">
              <v-container>
                <v-layout row class="banner">
                  <consumption-form/>
                  <v-dialog
                      v-model="dialog_lead_form"
                      fullscreen
                      hide-overlay
                      transition="dialog-bottom-transition"
                      scrollable
                  >
                      <lead-form/>
                  </v-dialog>
                </v-layout>
              </v-container>
            </v-col>
        </v-row>
      </v-img>
    </v-row>
    <how-works :config="landingPageConfig"/>
    <our-members :config="landingPageConfig" class="ourmembers"/>
    <about-enercred :config="landingPageConfig" class="d-none d-md-block"/>
    <about-enercred-mobile :config="landingPageConfig" class="d-md-none"/>
  </v-container>
</template>
<script>
import { mapActions, mapState } from 'vuex'
// import BannerSimulador from '../components/lp/BannerSimulador.vue'
import ConsumptionForm from '../components/Simulador/ConsumptionForm.vue'
import HowWorks from '../components/lp/HowWorks.vue'
import OurMembers from '../components/lp/OurMembers.vue'
import AboutEnercred from '../components/lp/AboutEnercred.vue'
import AboutEnercredMobile from '../components/lp/AboutEnercredMobile.vue'
import { getUserByEmail } from "../services/users"

export default {
    components: {
        // BannerSimulador,
        ConsumptionForm,
        HowWorks,
        OurMembers,
        AboutEnercred,
        AboutEnercredMobile,
    },
    mounted() {
      if(this.referral_code) {
        this.getIndicator(this.referral_code)
      }
    },
    data() {
        return {
            dialog_lead_form: false,
            indicator: null
        }
    },
    computed: {
      ...mapState(['landingPageConfig', 'referral_code'])
    },
    methods: {
      ...mapActions(['getLead']),
      getIndicator(email) {
        return getUserByEmail(email)
          .then(users => {
            if(users.size > 0) {
              this.indicator = {id: users.docs[0].id, ...users.docs[0].data()}
            }
            
          })
          .catch(err => console.error(err))
      }
    }
}
</script>
<style scoped>
  .banner {
    color: white;
    align-content: center;
    align-content: space-around;
  }
  .home {
    background-size: cover;
  }
  .ourmembers {
    background: #7D3DFF;
  }
</style>