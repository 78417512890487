export default {
    isSocialLogin(state) {
        if(state.lead && state.lead.providerID) return true
        else return false
    },
    plansOrder(state) {
        return state.plans.sort((a, b) => (a.order > b.order) ? 1 : -1)
    },
    filteredUtilityCompanies(state) {
        let utilities = JSON.parse(JSON.stringify(state.utilityCompanies))
        utilities.push({value: 'outra', text: 'Outra'})
        return utilities
    }
}