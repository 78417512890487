var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 container", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { wrap: "" } },
        [
          _c("v-col", {
            staticClass: "d-none d-md-block bg",
            attrs: { cols: "12", xs: "12", md: "6", lg: "4" }
          }),
          _c(
            "v-col",
            {
              staticClass: "pa-0 form",
              attrs: { cols: "12", md: "6", lg: "4" }
            },
            [
              _c("steps", {
                attrs: {
                  step: 2,
                  total: 5,
                  itens: [
                    _vm.lead.installations_found
                      ? "Selecionar imóveis"
                      : "Conta de Luz",
                    "Formas de Pagamento"
                  ]
                }
              }),
              _c(
                "v-container",
                { staticClass: "pa-3" },
                [
                  _c("h3", { staticClass: "text-center pt-3 px-2" }, [
                    _vm._v(
                      " Anexe sua conta de luz para agilizar o processo de qualificação "
                    )
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "px-8 my-5" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "mx-0 mb-0 py-11 font-weight-light rounded-xl",
                          attrs: {
                            "min-width": "100%",
                            color: "#E9E5E5",
                            disabled: _vm.loadingParse
                          },
                          on: { click: _vm.pickIdEnergyBill }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column align-center" },
                            [
                              _vm.loadingParse
                                ? _c("v-progress-circular", {
                                    staticClass: "mb-3",
                                    attrs: {
                                      indeterminate: "",
                                      color: "primary"
                                    }
                                  })
                                : _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        size: "40",
                                        color: "primary",
                                        "aria-hidden": "true"
                                      }
                                    },
                                    [_vm._v(" mdi-cloud-upload-outline ")]
                                  ),
                              _vm.consumerUnits.length < 1
                                ? _c("span", { staticClass: "caption ml-4" }, [
                                    _vm._v(
                                      "Adicionar 2º via da conta de luz em .pdf"
                                    )
                                  ])
                                : _c("span", { staticClass: "caption ml-4" }, [
                                    _vm._v(
                                      "Adicionar outra Unidade Consumidora"
                                    )
                                  ])
                            ],
                            1
                          )
                        ]
                      ),
                      _c("input", {
                        key: _vm.inputKey,
                        ref: "idEnergyBillInput",
                        staticStyle: { display: "none" },
                        attrs: { type: "file", accept: "application/pdf" },
                        on: {
                          change: function($event) {
                            return _vm.pickedEnergyBill($event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm.consumerUnits.length > 0
                    ? _c("div", { staticClass: "text-container" }, [
                        _c(
                          "p",
                          {
                            staticClass: "py-1 text-weight-bold text subtitulo"
                          },
                          [_vm._v("Unidade consumidora selecionada: ")]
                        )
                      ])
                    : _c("div", { staticClass: "text-container" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-0 py-0 pb-6 pt-2 text-weight-bold text subtitulo"
                          },
                          [
                            _vm._v("Ou se preferir, faça isso depois através "),
                            _c("br"),
                            _vm._v(" do nosso canal de atendimento")
                          ]
                        )
                      ]),
                  _c(
                    "div",
                    _vm._l(_vm.consumerUnits, function(uc, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "uc mx-5 my-4" },
                        [
                          _c("div", { staticClass: "uc-data" }, [
                            _c("p", { staticClass: "font-weight-bold title" }, [
                              _vm._v(_vm._s(uc.installation_number))
                            ]),
                            _c("p", { staticClass: "body-2" }, [
                              _vm._v(" " + _vm._s(_vm.address(uc.address)))
                            ])
                          ]),
                          _c(
                            "a",
                            {
                              staticClass: "black--text ml-4 trash-btn",
                              on: {
                                click: function($event) {
                                  return _vm.removeUc(index)
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    size: "20",
                                    color: "red",
                                    "aria-hidden": "true"
                                  }
                                },
                                [_vm._v(" mdi-trash-can-outline ")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none",
                      attrs: {
                        color: "secondary",
                        block: "",
                        rounded: "",
                        large: "",
                        loading: _vm.loadingBill,
                        disabled: _vm.loadingParse
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.nextStep($event)
                        }
                      }
                    },
                    [_vm._v("Continuar ")]
                  ),
                  _vm.lead.utility
                    ? _c("h4", { staticClass: "py-2 text-center" }, [
                        _c(
                          "a",
                          {
                            staticClass: "text-subtitle-2 black--text",
                            attrs: { href: _vm.utilityLink, target: "_blank" }
                          },
                          [
                            _vm._v(
                              "Obter segunda via da conta de luz da " +
                                _vm._s(_vm.utilityName)
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c("v-col", {
            staticClass: "d-none d-md-block bg",
            attrs: { cols: "12", xs: "12", md: "6", lg: "4" }
          })
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.color, top: "", dark: "" },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _c("span", { staticClass: "mr-auto" }, [
            _vm._v(_vm._s(_vm.snackMessage))
          ]),
          _c(
            "v-icon",
            {
              staticClass: "ml-3",
              attrs: { size: "16" },
              on: {
                click: function($event) {
                  _vm.snackbar = false
                }
              }
            },
            [_vm._v(" mdi-close-circle ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }