var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen:
          _vm.$vuetify.breakpoint.xsOnly || _vm.$vuetify.breakpoint.mdOnly,
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
        scrollable: "",
        "max-width": "500"
      },
      model: {
        value: _vm.dialog_howworks,
        callback: function($$v) {
          _vm.dialog_howworks = $$v
        },
        expression: "dialog_howworks"
      }
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { justify: "space-around" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-img",
                    {
                      attrs: {
                        height: "100vh",
                        src: require("@/assets/usinas/5.jpg"),
                        width: "100%",
                        "aspect-ratio": "2",
                        gradient:
                          "to top right, rgba(100,115,201,.25), rgba(25,32,72,.98)"
                      }
                    },
                    [
                      _c(
                        "v-app-bar",
                        { attrs: { flat: "", color: "rgba(0, 0, 0, 0)" } },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { dark: "", text: "" },
                              on: {
                                click: function($event) {
                                  _vm.dialog_howworks = false
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { color: "white" } }, [
                                _vm._v("mdi-close")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-img", {
                        staticClass: "ml-10 mt-16",
                        attrs: {
                          src: require("@/assets/novo/logo_icon_branco.png"),
                          "max-width": "50px"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "d-flex align-end" },
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass:
                                "white--text mt-1 display-1 align-end",
                              staticStyle: { padding: "100px 20px 20px 20px" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "display-1 align-end" },
                                [
                                  _vm._v(
                                    "Migre sua conta de luz para a Juntos Energia"
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font-weight-light white--text headline pt-6"
                                },
                                [
                                  _vm._v(
                                    "É diferente, é economico e é inteligente. Inteligente porque é legal e você passa a consumir uma energia 100% limpa, mais barata, sem taxas de adesão e sem obras."
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "text-center pt-12 pb-12" },
                        [
                          _c(
                            "v-btn",
                            { attrs: { icon: "", href: "#how_work" } },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "text-center",
                                  attrs: { color: "white", "x-large": "" }
                                },
                                [_vm._v("mdi-arrow-down-circle-outline")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "pa-6 pt-12 justify-center text-left",
                      staticStyle: { background: "#efefef" },
                      attrs: { id: "how_work" }
                    },
                    [
                      _c("h3", { staticClass: "pt-1" }, [
                        _vm._v("COMO FUNCIONA")
                      ]),
                      _c(
                        "v-row",
                        { staticClass: "pt-6 pl-3 pr-12" },
                        [
                          _c("v-col", { attrs: { cols: "3" } }, [
                            _c("h1", { staticClass: "blue--text" }, [
                              _vm._v("1.")
                            ])
                          ]),
                          _c("v-col", { attrs: { cols: "9" } }, [
                            _vm._v(
                              " Você contrata sua energia diretamente de uma de nossas "
                            ),
                            _c("strong", [_vm._v("usinas solares")]),
                            _vm._v(". ")
                          ]),
                          _c("v-col", { attrs: { cols: "3" } }, [
                            _c("h1", { staticClass: "blue--text" }, [
                              _vm._v("2.")
                            ])
                          ]),
                          _c("v-col", { attrs: { cols: "9" } }, [
                            _vm._v(" Nós "),
                            _c("strong", [_vm._v("comunicamos")]),
                            _vm._v(" a CEMIG que agora você utiliza da "),
                            _c("strong", [_vm._v("energia gerada")]),
                            _vm._v(" pela Juntos Energia. ")
                          ]),
                          _c("v-col", { attrs: { cols: "3" } }, [
                            _c("h1", { staticClass: "blue--text" }, [
                              _vm._v("3.")
                            ])
                          ]),
                          _c("v-col", { attrs: { cols: "9" } }, [
                            _c("strong", [_vm._v("Todos os meses")]),
                            _vm._v(
                              " a CEMIG avalia a energia gerada pela usina e "
                            ),
                            _c("strong", [_vm._v("desconta automaticamente")]),
                            _vm._v(" da sua conta de luz. ")
                          ]),
                          _c("v-col", { attrs: { cols: "3" } }, [
                            _c("h1", { staticClass: "blue--text" }, [
                              _vm._v("4.")
                            ])
                          ]),
                          _c("v-col", { attrs: { cols: "9" } }, [
                            _vm._v(
                              " Nós emitimos uma fatura para você, referente a energia limpa consumida, "
                            ),
                            _c("strong", [_vm._v("com os descontos")]),
                            _vm._v(" de acordo com o seu plano. ")
                          ]),
                          _c("v-col", { attrs: { cols: "3" } }, [
                            _c("h1", { staticClass: "blue--text" }, [
                              _vm._v("5.")
                            ])
                          ]),
                          _c("v-col", { attrs: { cols: "9" } }, [
                            _vm._v(" Viu só, você começa a "),
                            _c("strong", [_vm._v("economizar todos os meses")]),
                            _vm._v(
                              " sem nenhum esforço, sem nenhuma obra. Pode morar em casa, apartamento, ser proprietário ou morar de aluguel. "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "pt-6 pl-3 pr-6" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-6 mb-6 justify-center",
                              attrs: { outlined: "", block: "", rounded: "" },
                              on: {
                                click: function($event) {
                                  _vm.dialog_howworks = false
                                }
                              }
                            },
                            [_vm._v(" fechar ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ma-4 pa-3 pt-12 pb-12 pr-8 justify-center text-left"
                    },
                    [
                      _c(
                        "h2",
                        { staticClass: "blue--text font-weight-regular" },
                        [_vm._v("É legal")]
                      ),
                      _c(
                        "v-layout",
                        { attrs: { "align-center": "" } },
                        [
                          _c("v-img", {
                            attrs: {
                              src: require("@/assets/howworks/aneel.jpg"),
                              "max-width": "50%",
                              align: "center"
                            }
                          }),
                          _c("v-img", {
                            attrs: {
                              src: require("@/assets/howworks/governo.gif"),
                              "max-width": "50%",
                              align: "center"
                            }
                          })
                        ],
                        1
                      ),
                      _c("h2", { staticClass: "font-weight-regular pr-1" }, [
                        _vm._v(
                          " O projeto de lei 5.829 de 2019, sancionado no início de 2022 na Lei 14.300/22, institui o Marco Legal da Microgeração e Minigeração Distribuída. A lei regulamenta as modalidades de geração, o Sistema de Compensação de Energia Elétrica (SCEE) e o Programa de Energia Renovável Social (PERS) "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "pa-8 pt-12 pb-12 pr-8 justify-center text-left  white--text",
                      staticStyle: { background: "#00a4ff" }
                    },
                    [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "yellow--text font-weight-regular pt-12 justify-center"
                        },
                        [_vm._v("Por um mundo mais sustentável")]
                      ),
                      _c(
                        "h2",
                        {
                          staticClass:
                            "font-weight-regular pr-12 pb-12 justify-center"
                        },
                        [
                          _vm._v(
                            " Você entra para uma comunidade que economiza e ajuda o planeta, garantindo um futuro sustentável para nós e nossas familias. "
                          )
                        ]
                      )
                    ]
                  ),
                  _c("v-img", {
                    staticClass: "text-center justify-center",
                    attrs: { src: require("@/assets/howworks/solar.png") }
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ma-4 pa-3 pt-0 pb-0 pr-8 justify-center text-left  black--text"
                    },
                    [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "blue--text darken-2 font-weight-regular pt-12 justify-center"
                        },
                        [_vm._v("Pelo direito de escolha e economia")]
                      ),
                      _c(
                        "h2",
                        { staticClass: "font-weight-regular pr-12 pb-2" },
                        [
                          _vm._v(" Agora você pode "),
                          _c("strong", [_vm._v("economizar na conta de luz")]),
                          _vm._v(
                            " da sua casa ou empresa sem ter de pagar nada a mais por isso, sem obras e instalações caras, é 100% digital, é simples e 100% confiavel."
                          ),
                          _c("br"),
                          _c("strong", [
                            _vm._v(
                              "Já são mais de 90.000 mineiros que aderiram um novo modo de comprar energia, agora é a sua vez."
                            )
                          ])
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "pa-6 pt-4 justify-center text-left" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-6 mb-6 justify-center",
                          attrs: { outlined: "", block: "", rounded: "" },
                          on: {
                            click: function($event) {
                              _vm.dialog_howworks = false
                            }
                          }
                        },
                        [_vm._v(" fechar ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }