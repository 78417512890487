import { firestore } from "../firebase/firestore"

export const getConsumerUnit = (id) => {
    return firestore.collection('consumer_units').doc(id).get()
};

export const getActiveConsumerUnitByCPF = (cpf) => {
    return firestore.collection('consumer_units').where('cpf', '==', cpf).where('status', 'in', ['activated', 'canceling', 'interrupted', 'interruting']).limit(1).get()
};

export const getActiveConsumerUnitByCustomerId = (customer_id) => {
    return firestore.collection('consumer_units').where('user_id', '==', customer_id).where('status', 'in', ['activated', 'canceling', 'interrupted', 'interruting']).limit(1).get()
};