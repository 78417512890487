var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0", attrs: { fluid: "", wrap: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", {
            staticClass: "d-none d-md-block bg",
            attrs: { cols: "12", xs: "12", md: "6", lg: "4" }
          }),
          _c(
            "v-col",
            {
              staticClass: "pa-2 form",
              staticStyle: { background: "#efefef" },
              attrs: { cols: "12", md: "6", lg: "4" }
            },
            [
              _vm.utility === "other"
                ? _c(
                    "v-container",
                    { staticClass: "pa-6" },
                    [
                      _c("v-img", {
                        attrs: {
                          src: require("@/assets/novo/logo_roxo.svg"),
                          width: "230px"
                        }
                      }),
                      _c("h1", { staticClass: "pt-12" }, [
                        _vm._v(_vm._s(_vm._f("firstName")(_vm.lead.name)))
                      ]),
                      _c("h3", [
                        _c("p", [_vm._v("Obrigado!")]),
                        _c("p", { staticClass: "body-1 font-weight-bold" }, [
                          _vm._v("A Juntos ainda não chegou na sua região. ")
                        ]),
                        _c("p", { staticClass: "body-1" }, [
                          _vm._v(
                            "Já iniciamos o processo de expansão nacional dos nossos serviços. Você já "
                          ),
                          _c("strong", [_vm._v("garantiu seu lugar")]),
                          _vm._v(
                            " na fila de espera, assim que chegarmos por ai, você será um dos "
                          ),
                          _c("strong", [_vm._v("primeiros a saber")]),
                          _vm._v(".")
                        ])
                      ]),
                      _c("v-divider"),
                      _c("social-media", { staticClass: "pt-9" })
                    ],
                    1
                  )
                : _c(
                    "v-container",
                    { staticClass: "pa-6" },
                    [
                      _c("v-img", {
                        attrs: {
                          src: require("@/assets/novo/logo_roxo.svg"),
                          width: "230px"
                        }
                      }),
                      _c("h1", { staticClass: "pt-12" }, [
                        _vm._v(_vm._s(_vm._f("firstName")(_vm.lead.name)))
                      ]),
                      _c("h3", [
                        _c("p", [_vm._v("Obrigado!")]),
                        _c("p", { staticClass: "body-1 font-weight-bold" }, [
                          _vm._v(
                            " A Juntos acabou de lançar na sua região e você poderá ser um dos primeiros do estado a fazer a portabilidade de energia limpa da Juntos! Os nossos especialistas vão entrar em contato para tirar dúvidas e fazer a migração sem burocracia. "
                          )
                        ]),
                        _c("p", { staticClass: "body-1" }, [
                          _vm._v(
                            "Fique de olho no seu Telefone, WhatsApp ou E-mail (confira sempre na sua caixa de spam pelo e-mail vendas@juntosenergia.com.br)"
                          )
                        ]),
                        _c("p", { staticClass: "body-1" }, [
                          _vm._v("Até mais!")
                        ])
                      ]),
                      _c("v-divider"),
                      _c("social-media", { staticClass: "pt-9" })
                    ],
                    1
                  )
            ],
            1
          ),
          _c("v-col", {
            staticClass: "d-none d-md-block bg",
            attrs: { cols: "12", xs: "12", md: "6", lg: "4" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }