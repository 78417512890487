<template>
    <v-container fluid fill-height class="pt-8 pb-6">
        <v-row align="center" class="howworks">
            <v-col cols="12" md="6" class="pa-lg-16 pa-sm-6 pb-sm-0">
                <v-col cols="12" lg="12" class="pr-lg-16 pl-lg-16 pt-lg-12 pa-sm-6">
                    <h2 class="blue--text d-lg-none">Saiba mais</h2>
                    <h1 class="blue--text d-none d-lg-block">Saiba mais</h1>
                </v-col>
                <!-- <iframe 
                    width="100%" 
                    class="rounded-lg" 
                    height="250px" 
                    :src="'https://www.youtube.com/embed/' + (config && config.movie ? config.movie : 'aXmcmbiNcjg') + '?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0'"
                    title="Saiba mais em Juntos Energia"
                    frameborder="0"
                    allowfullscreen
                /> -->
            </v-col>
            <v-col cols="12" md="6" class="pa-lg-16 pa-sm-3">
                <v-list flat>
                        <v-list-item
                        v-for="(item, i) in items"
                        :key="i"
                        >
                        <v-list-item-icon>
                            <!-- <v-icon color="blue darken-3" large v-text="item.icon"></v-icon> -->
                            <v-img :src="require('@/assets/benefits/' + item.icon)" width="60px"/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <div v-html="item.text"/>
                            <!-- <v-list-item-subtitle v-html="item.text"></v-list-item-subtitle> -->
                        </v-list-item-content> 
                        </v-list-item>
                    <!-- </v-list-item-group> -->
                </v-list>
                <div class="text-center"><v-btn color="primary" href="#simular" large rounded class="pl-16 pr-16">{{config.call_to_action || 'SIMULE AGORA'}}</v-btn></div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
  export default {
    props: ['config'],
    data: () => ({
      items: [
        { text: 'Pra quem quer fazer diferença no mundo e ainda não sabe como', icon: '041-sprout.svg' },
        { text: 'Pra quem precisa economizar', icon: '043-energy-saving-light.svg' },
        { text: 'Pra quem quer simplicidade e praticidade', icon: '039-charging.svg' },
        { text: 'Pra quem quer ter poder de escolha', icon: '004-solar-panel.svg' },
        { text: 'Pra quem mora em casa, em apartamento e até de aluguel', icon: '045-renewable-energy.svg' },
        { text: 'Sem obras e sem manutenção e nenhum investimento inicial', icon: '012-energy.svg' },
      ],
    }),
  }
</script>
<style scoped>
    .howworks {
        min-height: 100vh;
    }
</style>